const createHooks = require('./createHooks')

const createFedopsOptions = appLogger => {
  const { startHook, endHook } = createHooks(appLogger)
  // custom timeoutHook for viewer-app fedops logger. Logs with level info until stabilized, to prevent false alarms
  const timeoutHook = ({ name, timeout }) =>
    appLogger.info(`interaction ${name} timed out`, {
      extra: {
        interactionTimeout: timeout
      }
    })

  const fedopsLoggerOptions = {
    interactionTimeout: 50000,
    startHook,
    endHook,
    timeoutHook
  }

  return fedopsLoggerOptions
}

module.exports = createFedopsOptions
