'use strict'
const forEach_ = require('lodash/forEach')
const includes_ = require('lodash/includes')
const unescape_ = require('lodash/unescape')
const values_ = require('lodash/values')
const isEmpty_ = require('lodash/isEmpty')
const get_ = require('lodash/get')
const Maybe = require('folktale/maybe')

const { Text } = require('@wix/dbsm-common/src/componentTypes')

const isInputComponent = require('@wix/dbsm-common/src/isInputComponent')
const isRecord = require('../../helpers/isRecord')
const { FIELD_TYPES } = require('@wix/dbsm-common/src/collections/consts')
const {
  isFieldFromReferencedCollection,
  getReferenceFieldName
} = require('@wix/dbsm-common/src/reference-fields/fieldPath')

const { selectCurrentRecord } = require('../../dataset-controller/rootReducer')
const convertValueToString = require('../../helpers/convertValueToString')
const getFieldValue = require('../../helpers/getFieldValue')
const formatValue = require('../../helpers/formatValue')
const addComponentEventListener = require('../addComponentEventListener')
const {
  selectNextDynamicPageUrl,
  selectPreviousDynamicPageUrl
} = require('../../dataset-controller/rootReducer')

module.exports = (
  {
    getState,
    datasetApi,
    wixSdk,
    errorReporter,
    platformUtilities,
    eventListeners: { register },
    getFieldType,
    applicationCodeZone,
    databindingVerboseReporter
  },
  { shouldConvertToString } = { shouldConvertToString: true }
) => {
  const setFieldOnComponentChange = (component, properties, actions) => {
    const getValue = (fieldName, value) => {
      return getFieldType(fieldName)
        .chain(fieldType => {
          if (fieldType === 'reference') {
            const referencedRecord = actions.fetchRecordById(
              value, // referenced record id
              fieldName
            )
            return referencedRecord
          }
          return Maybe.Nothing()
        })
        .getOrElse(value)
    }

    addComponentEventListener(
      component,
      'onChange',
      event => {
        const propName = properties.checked ? 'checked' : 'value'
        const fieldName = properties[propName].fieldName
        const value = getValue(fieldName, event.target[propName])

        actions.setFieldInCurrentRecordAndSynchronize(
          fieldName,
          value,
          component.uniqueId
        )
      },
      applicationCodeZone
    )
  }

  const getNavigateUrl = (navigate, record) => {
    if (navigate.fieldName) {
      return record[navigate.fieldName]
    }

    if (navigate.linkObject) {
      return platformUtilities.links.toUrl(navigate.linkObject)
    }
  }

  const navigateToDynamicPage = dynamicPageUrlState =>
    dynamicPageUrlState.matchWith({
      Empty() {},
      Loading() {},
      Loaded({ url }) {
        wixSdk.location.to(url)
      }
    })

  const dispatchActionOnComponentEvent = (
    component,
    eventName,
    action,
    postAction
  ) => {
    component[eventName](async function() {
      try {
        if (action === 'nextDynamicPage') {
          navigateToDynamicPage(selectNextDynamicPageUrl(getState()))
          return
        }
        if (action === 'previousDynamicPage') {
          navigateToDynamicPage(selectPreviousDynamicPageUrl(getState()))
          return
        }
        const record = await Promise.resolve(datasetApi[action]())
        if (postAction && postAction.navigate) {
          const url = getNavigateUrl(postAction.navigate, record)
          wixSdk.location.to(url)
        }
      } catch (e) {
        errorReporter(`${action} operation failed:`, e)
      }
    })
  }

  const getValue = value =>
    shouldConvertToString ? convertValueToString(value) : value

  const setPropAtPath = (component, propPath, valueToSet) => {
    const propNames = propPath.split('.')

    propNames.reduce((objToSet, propName, currIndex) => {
      if (currIndex < propNames.length - 1) {
        return objToSet[propName]
      }
      objToSet[propName] = valueToSet
    }, component)
  }

  const setValueToComponent = (
    component,
    componentType,
    propPath,
    newValue,
    fieldType,
    format
  ) => {
    const valueToSet =
      fieldType === 'reference' && isRecord(newValue)
        ? getValue(newValue._id)
        : getValue(newValue)

    //set_ doesn't work if oldValue and newValue are the same.
    setPropAtPath(component, propPath, valueToSet)

    if (fieldType === FIELD_TYPES.URL && componentType === Text) {
      const compText = component.text
      component.text = `<a href=${compText} target="_blank" style="text-decoration: underline">${compText}</a>`
      const compHTML = component.html
      component.text = ''
      component.html = unescape_(compHTML)
    }
  }

  const updateComponentFromCurrentRecord = (
    { connectionConfig: { properties }, component, componentType },
    actions,
    updatedFields = []
  ) => {
    const record = selectCurrentRecord(getState())
    if (!record) {
      return
    }
    logVerboseValueDescription(component, properties, record)
    forEach_(properties, ({ fieldName, format }, propPath) => {
      try {
        if (
          updatedFields.length === 0 ||
          includes_(
            updatedFields,
            isFieldFromReferencedCollection(fieldName)
              ? getReferenceFieldName(fieldName)
              : fieldName
          )
        ) {
          const fieldType = getFieldType(fieldName).getOrElse('')
          setValueToComponent(
            component,
            componentType,
            propPath,
            formatValue(getFieldValue(record, fieldName), {
              format,
              wixSdk,
              fieldType
            }),
            fieldType
          )
        }
      } catch (e) {
        errorReporter(`Failed setting ${propPath}:`, e)
      }
    })
  }

  const syncValidityIndication = ({ component, componentType }, actions) => {
    const inputComponent = isInputComponent.byType(componentType)
    if (!inputComponent) {
      return
    }
    // The below checks are currently costly as they each instantiate
    // a new recordStore API object.
    // In a repeater scenario, we run this code a lot - a function of
    // the number of repeater items and the number of connected components
    // in each item.
    // Therefore, it is best to exit early here.
    const pristine = actions.isCurrentRecordPristine(getState())
    const newRecord = actions.isCurrentRecordNew(getState())
    if (pristine && newRecord) {
      component.resetValidityIndication && component.resetValidityIndication()
    }
  }

  const sync = actions => () => {
    actions.refresh()
  }

  const applyBehaviorToComponent = (behavior, component) => {
    let ignoreNextIndexChange = false
    switch (behavior) {
      case 'saveSuccessFeedback':
        register('beforeSave', () => component.hide())
        register('afterSave', () => {
          component.show()
          ignoreNextIndexChange = true
        })
        register('currentIndexChanged', () => {
          if (ignoreNextIndexChange) {
            ignoreNextIndexChange = false
          } else {
            component.hide()
          }
        })
        register('itemValuesChanged', () => component.hide())
        break
      case 'saveFailureFeedback':
        register('beforeSave', () => component.hide())
        register('currentIndexChanged', () => component.hide())
        register('datasetError', operationName => {
          if (operationName === 'save') {
            component.show()
          }
        })
        break
    }
  }

  const isBoundToInputProperty = properties =>
    get_(properties, 'value') || get_(properties, 'checked')

  const logVerboseForBinding = (component, connectionConfig) => {
    const { properties, events, behaviors } = connectionConfig
    const bindingDescription = {}

    forEach_(properties, ({ fieldName }, propName) => {
      bindingDescription[propName] = fieldName
    })
    forEach_(events, ({ action }, eventName) => {
      bindingDescription[eventName] = action
    })
    forEach_(behaviors, ({ type: behavior }) => {
      bindingDescription.text = behavior
    })

    databindingVerboseReporter.logBinding({
      component,
      bindingDescription
    })
  }

  const logVerboseValueDescription = (component, properties, record) => {
    const valueDescription = {}

    forEach_(properties, ({ fieldName }, propPath) => {
      const valueToSet = getValue(getFieldValue(record, fieldName))
      valueDescription[propPath] = valueToSet
    })

    databindingVerboseReporter.logValue({
      component,
      valueDescription
    })
  }

  return {
    isValidContext({ connectionConfig }) {
      return values_(connectionConfig).find(
        configValue => !isEmpty_(configValue)
      )
    },

    bindToComponent({ connectionConfig, component }, actions) {
      const { properties, events, filters, behaviors } = connectionConfig

      if (isBoundToInputProperty(properties)) {
        setFieldOnComponentChange(component, properties, actions)
      }

      if (events) {
        forEach_(events, ({ action, postAction }, eventName) => {
          dispatchActionOnComponentEvent(
            component,
            eventName,
            action,
            postAction
          )
        })
      }

      if (filters) {
        if (typeof component.onChange === 'function') {
          addComponentEventListener(
            component,
            'onChange',
            sync(actions),
            applicationCodeZone
          )
        }
      }

      if (behaviors) {
        forEach_(behaviors, ({ type: behavior }) => {
          applyBehaviorToComponent(behavior, component)
        })
      }

      logVerboseForBinding(component, connectionConfig)
    },

    currentRecordModified(componentAdapterContext, actions, updatedFields) {
      updateComponentFromCurrentRecord(
        componentAdapterContext,
        actions,
        updatedFields
      )
      syncValidityIndication(componentAdapterContext, actions)
    },

    recordSetLoaded(componentAdapterContext, actions) {
      updateComponentFromCurrentRecord(componentAdapterContext, actions)
      syncValidityIndication(componentAdapterContext, actions)

      const { behaviors } = componentAdapterContext.connectionConfig
      if (behaviors) {
        forEach_(behaviors, ({ type: behavior }) => {
          if (behavior === 'saveSuccessFeedback') {
            componentAdapterContext.component.hide()
          }
        })
      }
    },

    currentViewChanged(componentAdapterContext, actions) {
      updateComponentFromCurrentRecord(componentAdapterContext, actions)
      syncValidityIndication(componentAdapterContext, actions)
    },

    currentIndexChanged(componentAdapterContext, actions) {
      updateComponentFromCurrentRecord(componentAdapterContext, actions)
      syncValidityIndication(componentAdapterContext, actions)
    }
  }
}
