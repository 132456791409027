'use strict'
const noop_ = require('lodash/noop')
const remove_ = require('lodash/remove')
const reportCallbackError = require('../logging/reportCallbackError')
const { verboseEvent } = require('../dataset-api/verbosity')

const eventListenersCreator = (
  firePlatformEvent = noop_,
  errorReporter = noop_,
  verboseReporter = noop_
) => {
  let isDisposed = false
  let callbacks = {}

  const getCallbacks = eventName =>
    callbacks[eventName] ? callbacks[eventName] : (callbacks[eventName] = []) // WeakSet is not iterable.

  const register = (eventName, cb) => {
    if (isDisposed) {
      return noop_
    }

    getCallbacks(eventName).push(cb)
    return () => {
      remove_(getCallbacks(eventName), f => f === cb)
    }
  }

  const executeHooks = (eventName, ...args) => {
    return Promise.all(
      getCallbacks(eventName).map(cb => {
        try {
          return Promise.resolve(cb(...args))
        } catch (err) {
          return Promise.reject(err)
        }
      })
    )
  }

  const fireEvent = (eventName, ...args) => {
    verboseEvent(verboseReporter, eventName, ...args)
    firePlatformEvent(eventName, ...args)
    executeHooks(eventName, ...args).catch(
      reportCallbackError(eventName, errorReporter)
    )
  }

  const dispose = () => {
    isDisposed = true
    callbacks = {}
  }

  return {
    register,
    executeHooks,
    fireEvent,
    dispose
  }
}

module.exports = eventListenersCreator
