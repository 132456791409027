const wixFormattingCreator = require('@wix/wix-code-formatting')
const formatTypes = require('@wix/dbsm-common/src/connection-config/formatTypes')

const formatByType = (value, { format, wixSdk }) => {
  switch (format.type) {
    case formatTypes.DATETIME:
      if (value.constructor.name !== 'Date') {
        return value
      }

      const wixFormatting = wixFormattingCreator({
        locale: wixSdk.window.locale
      })
      return wixFormatting.formatDateTime(value, format.params.dateFormat)
    default:
      return value
  }
}

module.exports = (value, { format, wixSdk }) => {
  if (format) {
    return formatByType(value, { format, wixSdk })
  }

  return value
}
