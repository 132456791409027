const mapValues_ = require('lodash/mapValues')

const { assertDatasetTypeIsRouter } = require('./datasetApiAssertions')

/**
 * @class DynamicDataset
 * @mixes wix-dataset.Dataset
 * @summary A [dataset](wix-dataset.html) connects page elements on a dynamic page to a set of items in a data collection.
 * @memberof wix-dataset
 * @definitionId dataBinding.routerDataset
 */

const routerDatasetApiCreator = ({
  datasetType,
  siblingDynamicPageUrlGetter
}) => {
  const routerDatasetApi = {
    /**
     * @function getNextDynamicPage
     * @memberof wix-dataset.DynamicDataset
     * @summary Gets the next dynamic page URL.
     * @syntax
     * function getNextDynamicPage(): Promise<String>
     * @description
     *  The `getNextDynamicPage()` function can only be called on a dynamic page
     *  dataset.
     *
     *  The function returns a Promise that is resolved to the relative URL of the
     *  next dynamic page or `null` if there is no next page.
     *
     *  The next page is determined by the [lexicographical order](https://en.wikipedia.org/wiki/Lexicographical_order)
     *  of the dynamic page relative URLs. You can see all of the relative URLs for
     *  a dynamic page by viewing the collection connected to the page.
     *
     *  URLs for items that do not match the dataset's filters are not returned.
     *
     *  For example, consider the following situation:
     *
     *  + You have a collection of employees that contains a boolean field named **Active**.
     *  + Your dynamic page datset is filtered to only show employees where **Active** is **true**.
     *  + Some of your dynamic page URLs include:
     *    + employees/Alice
     *    + employees/Bob
     *    + employees/Cathy
     *
     *
     *  If Bob is not an active employee, when you call `getNextDynamicPage()`
     *  from Alice's page, the returned Promise will resolve to `"employees/Cathy"`.
     *
     *  Pass the returned URL to the [`to()`](wix-location.html#to) function to
     *  navigate to the next dynamic page.
     * @snippet [getNextDynamicPage.es6=Get the URL of the next dynamic page]
     * @snippet [getNextDynamicPage_to.es6=Navigate to the next dynamic page]
     * @returns {Promise}
     * @fulfill {external:String} The URL of the next dynamic page, or `null` if there is no next page.
     * @see [getPreviousDynamicPage( )](#getPreviousDynamicPage)
     * @instance
     */
    async getNextDynamicPage() {
      return siblingDynamicPageUrlGetter != null
        ? siblingDynamicPageUrlGetter.getNextDynamicPageUrl()
        : null
    },

    /**
     * @function getPreviousDynamicPage
     * @memberof wix-dataset.DynamicDataset
     * @summary Gets the previous dynamic page URL.
     * @syntax
     * function getPreviousDynamicPage(): Promise<String>
     * @description
     *  The `getPreviousDynamicPage()` function can only be called on a dynamic page
     *  dataset.
     *
     *  The function returns a Promise that is resolved to the relative URL of the
     *  previous dynamic page or `null` if there is no previous page.
     *
     *  The previous page is determined by the [lexicographical order](https://en.wikipedia.org/wiki/Lexicographical_order)
     *  of the dynamic page relative URLs. You can see all of the relative URLs for
     *  a dynamic page by viewing the collection connected to the page.
     *
     *  URLs for items that do not match the dataset's filters are not returned.
     *
     *  For example, consider the following situation:
     *
     *  + You have a collection of employees that contains a boolean field named **Active**.
     *  + Your dynamic page datset is filtered to only show employees where **Active** is **true**.
     *  + Some of your dynamic page URLs include:
     *    + employees/Alice
     *    + employees/Bob
     *    + employees/Cathy
     *
     *
     *  If Bob is not an active employee, when you call `getPreviousDynamicPage()`
     *  from Cathy's page, the returned Promise will resolve to `"employees/Alice"`.
     *
     *  Pass the returned URL to the [`to()`](wix-location.html#to) function to
     *  navigate to the previous dynamic page.
     * @snippet [getPreviousDynamicPage.es6=Get the URL of the previous dynamic page]
     * @snippet [getPreviousDynamicPage_to.es6=Navigate to the previous dynamic page]
     * @returns {Promise}
     * @fulfill {external:String} The URL of the previous dynamic page, or `null` if there is no previous page.
     * @see [getNextDynamicPage( )](#getNextDynamicPage)
     * @instance
     */
    async getPreviousDynamicPage() {
      return siblingDynamicPageUrlGetter
        ? siblingDynamicPageUrlGetter.getPreviousDynamicPageUrl()
        : null
    }
  }
  return mapValues_(routerDatasetApi, (fn, functionName) => (...args) => {
    assertDatasetTypeIsRouter(datasetType, functionName)
    return fn(...args)
  })
}

module.exports = routerDatasetApiCreator
