'use strict'

const noop_ = require('lodash/noop')
const Result = require('folktale/result')
const { union } = require('folktale/adt/union')

const id = 'SYSTEM_TRACING'

const Environment = union('Environment', {
  NotInitialized() {},
  Initialized({ reportTrace }) {
    return { reportTrace }
  }
})

function doTrace(reportTrace, params) {
  Result.try(() => reportTrace(params))
}

const systemTracingHandlerCreator = () => {
  let environment = Environment.NotInitialized()

  const systemTracingHandler = () => ({
    id,
    init: ({ reportTrace }) => {
      environment = Environment.Initialized({ reportTrace })
    },
    log: logEvent => {
      environment.matchWith({
        Initialized: ({ reportTrace }) => {
          logEvent.matchWith({
            TraceStart: ({ actionName, startParams: params }) => {
              doTrace(
                reportTrace,
                Object.assign({ actionName, tracePosition: 'before' }, params)
              )
            },
            TraceEnd: ({
              actionName,
              endParams,
              durationMs: actionDurationMs
            }) => {
              doTrace(
                reportTrace,
                Object.assign(
                  { actionName, tracePosition: 'after', actionDurationMs },
                  endParams
                )
              )
            },
            [union.any]: noop_
          })
        },
        NotInitialized: () => {
          throw new Error(
            `You cannot report to system tracer before setting the logger environment.
              Make sure you call logger.init before reporting.`
          )
        }
      })
    }
  })

  return systemTracingHandler
}

module.exports.id = id
module.exports.systemTracingHandlerCreator = systemTracingHandlerCreator
