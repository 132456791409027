const isError_ = require('lodash/isError')
const negate_ = require('lodash/negate')
const {
  extendConsoleError,
  isAppInvolvedWithError,
  addUnhandledRejectionListener,
  shouldReportException
} = require('@wix/dbsm-common/src/raven/supplementaryErrorHandlers')

const isErrorHandled = require('./error-boundaries/errorBoundaries')

function getErrorStacktraceFirstLine(err) {
  const printedError = err.stack.toString()
  return printedError.substring(printedError.indexOf('https://')).split('\n')[0]
}

const isAppInFirstLineInStacktrace = appName => err =>
  getErrorStacktraceFirstLine(err).includes(appName)

module.exports = (globalScope, appName, cb) => {
  const handleException = error => {
    if (
      shouldReportException(
        error,
        isError_,
        negate_(isErrorHandled),
        isAppInvolvedWithError(appName)
      )
    ) {
      const options = {
        tags: Object.assign(
          { errorBoundaries: 'non-scoped' },
          {
            'errorBoundaries.isInFirstLine': isAppInFirstLineInStacktrace(
              appName
            )(error)
          }
        )
      }

      cb(error, options)
    }
  }

  extendConsoleError(globalScope, (...args) => handleException(args[0]))
  addUnhandledRejectionListener(global, evt => handleException(evt.reason))
}
