module.exports = function(logger) {
  function startHook({ name }) {
    logger.breadcrumb({
      category: 'interaction start',
      message: `interaction ${name} started`
    })
  }

  function endHook({ name, timeout, duration }) {
    if (duration > timeout) {
      logger.info(`interaction ${name} ended after timeout`, {
        extra: {
          interactionDuration: duration
        }
      })
    } else {
      logger.breadcrumb({
        category: 'interaction end',
        message: `interaction ${name} ended after ${duration} ms`
      })
    }
  }

  function timeoutHook({ name, timeout }) {
    logger.warn(`interaction ${name} timed out`, {
      extra: {
        interactionTimeout: timeout
      }
    })
  }

  return {
    startHook,
    endHook,
    timeoutHook
  }
}
