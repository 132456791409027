'use strict'

const Maybe = require('folktale/maybe')

const Immediate = require('./immediate')
const traceActions = require('../logging/traceActions')

module.exports = (hasPrefetchedData, recordStore, errorReporter, appLogger) => {
  const prefetchFirstRecord = service => {
    const fetchPromise = service
      .seed()
      .then(() =>
        service
          .getRecords(0, 1)
          .then(queryResult =>
            queryResult.chain(({ items }) => Maybe.Just(items[0]))
          )
      )
      .catch(e => {
        errorReporter('Failed to load initial data', e)
        return Maybe.Nothing()
      })

    return fetchPromise
  }

  const getFirstPrefetchedRecord = service =>
    service.getSeedRecords().matchWith({
      Empty: () => Immediate.resolve(Maybe.Nothing()),
      Results: ({ items }) => Immediate.resolve(Maybe.Just(items[0]))
    })

  const getFirstRecord = () =>
    recordStore().matchWith({
      Error: () => Promise.resolve(Maybe.Nothing()),
      Ok: ({ value: service }) =>
        service.hasSeedData()
          ? getFirstPrefetchedRecord(service)
          : appLogger.traceAsync(traceActions.pageReadyGetData(), () =>
              prefetchFirstRecord(service)
            )
    })

  return getFirstRecord(hasPrefetchedData)
}
