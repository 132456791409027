'use strict'

const { union } = require('folktale/adt/union')
const pull_ = require('lodash/pull')
const merge_ = require('lodash/merge')
const uniq_ = require('lodash/uniq')

const traceLevels = {
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error'
}

const LogEvent = union('LogEvent', {
  BI: ({ biEvent }) => ({ biEvent }),
  Breadcrumb: ({ breadcrumb }) => ({ breadcrumb }),
  TraceStart: ({
    traceId,
    actionName,
    level = traceLevels.INFO,
    startParams
  }) => ({
    traceId,
    actionName,
    level,
    startParams
  }),
  TraceEnd: ({
    traceId,
    actionName,
    level = traceLevels.INFO,
    startParams,
    endParams,
    durationMs
  }) => ({
    traceId,
    actionName,
    level,
    startParams,
    endParams,
    durationMs
  }),
  Info: ({ message, options, sessionData }) => ({
    message,
    options,
    sessionData
  }),
  Warn: ({ message, options, sessionData }) => ({
    message,
    options,
    sessionData
  }),
  Error: ({ error, options, sessionData }) => ({
    error,
    options,
    sessionData
  })
})

const createRegistrar = () => {
  const callbacks = []

  const register = cb => {
    callbacks.push(cb)
    return () => {
      pull_(callbacks, cb)
    }
  }
  const getCallbacks = () => callbacks.slice()

  return { register, getCallbacks }
}

const validateArgument = (argName, argValue) => {
  if (argValue == null) {
    throw new Error(`Logger: the argument ${argName} is required`)
  }
}

const loggerCreator = ({ handlerCreators = [] }) => {
  const handlers = handlerCreators.map(creator => creator())
  const sessionDataRegistrar = createRegistrar()
  const tracesMap = new Map()

  const getSessionData = () =>
    sessionDataRegistrar
      .getCallbacks()
      .reduce((acc, cb) => merge_(acc, cb()), {})

  const callInit = initParams => {
    handlers.forEach(handler =>
      handler.init(Object.assign({ logger }, initParams))
    )
  }

  const reportBI = biEvent => {
    handlers.forEach(handler => handler.log(LogEvent.BI({ biEvent })))
  }

  const reportBreadcrumb = breadcrumb => {
    handlers.forEach(handler =>
      handler.log(LogEvent.Breadcrumb({ breadcrumb }))
    )
  }

  const reportInfo = (message, options) => {
    handlers.forEach(handler =>
      handler.log(
        LogEvent.Info({ message, options, sessionData: getSessionData() })
      )
    )
  }

  const reportWarn = (message, options) => {
    handlers.forEach(handler =>
      handler.log(
        LogEvent.Warn({ message, options, sessionData: getSessionData() })
      )
    )
  }

  const reportError = (error, options) => {
    handlers.forEach(handler =>
      handler.log(
        LogEvent.Error({
          error,
          options,
          sessionData: getSessionData()
        })
      )
    )
  }

  const filterHandlersToReportTo = reportToHandlers => handler =>
    reportToHandlers.includes(handler.id)

  const traceStart = ({
    reportToHandlers,
    actionName,
    params: startParams,
    level
  }) => {
    validateArgument('actionName', actionName)
    validateArgument('reportToHandlers', reportToHandlers)

    const ts = Date.now()
    const traceId = uniq_()
    tracesMap.set(traceId, { reportToHandlers, actionName, startParams, ts })

    handlers
      .filter(filterHandlersToReportTo(reportToHandlers))
      .forEach(handler =>
        handler.log(
          LogEvent.TraceStart({ traceId, actionName, level, startParams })
        )
      )

    return traceId
  }

  const traceEnd = ({ traceId, params: endParams, level }) => {
    validateArgument('traceId', traceId)
    const trace = tracesMap.get(traceId)
    if (trace == null) {
      throw new Error(`Could not find a trace start for traceId ${traceId}`)
    }
    tracesMap.delete(traceId)

    const { reportToHandlers, actionName, startParams, ts } = trace
    const durationMs = Date.now() - ts

    handlers
      .filter(filterHandlersToReportTo(reportToHandlers))
      .forEach(handler =>
        handler.log(
          LogEvent.TraceEnd({
            traceId,
            actionName,
            level,
            startParams,
            endParams,
            durationMs
          })
        )
      )
  }

  const traceAsync = (traceStartParams, fn) => {
    const traceId = traceStart(traceStartParams)

    const promise = fn()
    promise
      .then(() => traceEnd({ traceId }))
      .catch(e => traceEnd({ traceId, params: { message: e } }))
    return promise
  }

  const logger = {
    addSessionData: sessionDataRegistrar.register,
    init: callInit,
    bi: reportBI,
    breadcrumb: reportBreadcrumb,
    info: reportInfo,
    warn: reportWarn,
    error: reportError,
    traceStart,
    traceEnd,
    traceAsync,
    traceLevels
  }

  return logger
}

module.exports.loggerCreator = loggerCreator
