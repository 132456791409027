'use strict'

const registerToUnexpectedErrors = require('./registerToUnexpectedErrors')
const errorBoundariesCreator = require('./error-boundaries/errorBoundaries')
const {
  errorBoundaryScopes: { USER_SCOPE, WIX_DATA_SCOPE, APPLICATION_SCOPE }
} = require('./error-boundaries/errorBoundaries')
const { loggerCreator } = require('./baseLogger')

const viewerLogger = ({ global, appName, handlerCreators }) => {
  const logger = loggerCreator({
    handlerCreators
  })

  const reportErrorWithZone = zone => (error, options) => {
    const optionsWithZone = Object.assign({}, options, { zone })
    logger.error(error, optionsWithZone)
  }

  const errorBoundaries = errorBoundariesCreator({
    [USER_SCOPE]: reportErrorWithZone(USER_SCOPE),
    [WIX_DATA_SCOPE]: reportErrorWithZone(WIX_DATA_SCOPE),
    [APPLICATION_SCOPE]: reportErrorWithZone(APPLICATION_SCOPE)
  })

  registerToUnexpectedErrors(
    global,
    appName,
    reportErrorWithZone(APPLICATION_SCOPE)
  )

  const loggerWithZones = Object.assign({}, logger, {
    error: reportErrorWithZone(APPLICATION_SCOPE),
    applicationCodeZone: errorBoundaries.applicationCodeZone,
    userCodeZone: errorBoundaries.userCodeZone,
    wixDataCodeZone: errorBoundaries.wixDataCodeZone
  })

  return loggerWithZones
}

module.exports.loggerCreator = viewerLogger
