'use strict'

const ExtendableError = require('es6-error')
const ERROR_TYPE = 'DatasetError'

class DatasetError extends ExtendableError {
  constructor(code, message) {
    super(message)
    this.name = ERROR_TYPE
    this.code = code
  }
}
module.exports = DatasetError

module.exports.isDatasetError = errorType => errorType === ERROR_TYPE
