'use strict'

const uniqBy_ = require('lodash/uniqBy')

const {
  GRID_ROLE,
  DROPDOWN_OPTIONS_ROLE,
  GALLERY_ROLE,
  MEDIA_GALLERY_ROLE,
  UPLOAD_BUTTON_ROLE,
  DETAILS_DATASET_ROLE,
  REPEATER_ROLE,
  PAGINATION_ROLE,
  DETAILS_REPEATER_ROLE,
  RATINGSDISPLAY_ROLE,
  RATINGSINPUT_ROLE
} = require('@wix/dbsm-common/src/connection-config/roles')
const isInputComponent = require('@wix/dbsm-common/src/isInputComponent')

const { createAdapterContext } = require('./context')

const { getScopeType, SCOPE_TYPES } = require('./scopeTypes')
const { findContainer } = require('./findContainers')

const defaultAdapter = require('./adapters/defaultAdapter')
const gridAdapter = require('./adapters/gridAdapter')
const dropdownOptionsAdapter = require('./adapters/dropdownOptionsAdapter')
const galleryAdapter = require('./adapters/galleryAdapter')
const uploadButtonAdapter = require('./adapters/uploadButtonAdapter')
const nullAdapter = require('./adapters/nullAdapter')
const connectedRepeaterAdapter = require('./adapters/connectedRepeaterAdapter')
const detailsRepeaterAdapter = require('./adapters/detailsRepeaterAdapter')
const paginationAdapter = require('./adapters/paginationAdapter')

const rolesToApiCreators = {
  default: defaultAdapter,
  [GRID_ROLE]: gridAdapter,
  [DROPDOWN_OPTIONS_ROLE]: dropdownOptionsAdapter,
  [GALLERY_ROLE]: galleryAdapter,
  [MEDIA_GALLERY_ROLE]: defaultAdapter,
  [UPLOAD_BUTTON_ROLE]: uploadButtonAdapter,
  [DETAILS_DATASET_ROLE]: nullAdapter,
  [REPEATER_ROLE]: connectedRepeaterAdapter,
  [PAGINATION_ROLE]: paginationAdapter,
  [DETAILS_REPEATER_ROLE]: detailsRepeaterAdapter,
  [RATINGSDISPLAY_ROLE]: defaultAdapter,
  [RATINGSINPUT_ROLE]: defaultAdapter
}

const createComponentAdapter = ({ role, adapterParams, componentType }) => {
  const shouldConvertToString =
    role !== RATINGSDISPLAY_ROLE &&
    role !== MEDIA_GALLERY_ROLE &&
    !isInputComponent.byType(componentType)

  const apiCreator = rolesToApiCreators[role] || rolesToApiCreators.default
  return apiCreator(adapterParams, { shouldConvertToString })
}

const createComponentAdapterContexts = ({
  connectedComponents,
  adapterApi,
  getFieldType,
  ignoreItemsInRepeater,
  $w,
  dependencies,
  adapterParams
}) => {
  const cacs = []

  connectedComponents.forEach(({ component, role }) => {
    const { type: componentType, id: componentId } = component
    if (ignoreItemsInRepeater) {
      const isComponentInsidePrimaryOrDetailsRepeater = findContainer(component)
        .map(container =>
          getScopeType(container.uniqueId, dependencies, connectedComponents)
        )
        .map(
          scopeType =>
            scopeType === SCOPE_TYPES.PRIMARY ||
            scopeType === SCOPE_TYPES.DETAILS
        )
        .getOrElse(false)

      if (isComponentInsidePrimaryOrDetailsRepeater) {
        return
      }
    }

    const adapterContext = createAdapterContext({
      getFieldType,
      role,
      component,
      componentType,
      componentId,
      $w,
      api: createComponentAdapter({ role, adapterParams, componentType })
    })

    if (adapterApi().isValidContext(adapterContext)) {
      cacs.push(adapterContext)
    }
  })

  return cacs
}

const createDetailsRepeatersAdapterContexts = (
  connectedComponents,
  getFieldType,
  dependencies,
  adapterParams
) => {
  const detailsRepeaters = []

  connectedComponents.forEach(({ component }) => {
    findContainer(component).chain(container => {
      const scopeType = getScopeType(
        container.uniqueId,
        dependencies,
        connectedComponents
      )
      if (scopeType === SCOPE_TYPES.DETAILS) {
        detailsRepeaters.push(container)
      }
    })
  })

  const uniqDetailsRepeaters = uniqBy_(detailsRepeaters, 'id')
  return uniqDetailsRepeaters.map(component => {
    const { type: componentType, id: componentId } = component
    return createAdapterContext({
      getFieldType,
      role: DETAILS_REPEATER_ROLE,
      component,
      componentType,
      componentId,
      api: createComponentAdapter({
        role: DETAILS_REPEATER_ROLE,
        adapterParams,
        componentType
      })
    })
  })
}

module.exports.createComponentAdapterContexts = createComponentAdapterContexts
module.exports.createDetailsRepeatersAdapterContexts = createDetailsRepeatersAdapterContexts
