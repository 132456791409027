const identity_ = require('lodash/identity')
const get_ = require('lodash/get')

const setupRaven = require('./setupRaven')
const { isDatasetError } = require('../../dataset-api/DatasetError')

module.exports = (Raven, globalScope, dsn, params = {}, appName) => {
  setupRaven(Raven, globalScope, dsn, params, appName)
  Raven.setDataCallback((data, originalCallback = identity_) => {
    if (isDatasetError(get_(data, ['exception', 'values', 0, 'type']))) {
      data.level = 'info'
    }

    return originalCallback(data)
  })
}
