const { logger, traceHandlerIds } = require('./loggerWithHandlers')
const breadcrumbsMiddleware = require('./breadcrumbsMiddleware')
const breadcrumbWrapper = require('./withBreadcrumbs')

module.exports = {
  logger,
  traceHandlerIds,
  breadcrumbsMiddleware,
  breadcrumbWrapper
}
