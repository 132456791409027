'use strict'

const get_ = require('lodash/get')
const values_ = require('lodash/values')
const includes_ = require('lodash/includes')
const merge_ = require('lodash/merge')
const defaultsDeep_ = require('lodash/defaultsDeep')
const noop_ = require('lodash/noop')
const pick_ = require('lodash/pick')
const uniq_ = require('lodash/uniq')
const without_ = require('lodash/without')

const DATASET_TYPES = require('@wix/dbsm-common/src/datasetTypes')

const defaultDatasetConfiguration = require('@wix/dbsm-common/src/dataset-configuration/defaults')
const {
  parseUrlPattern
} = require('@wix/dbsm-common/src/dynamic-pages/urlUtils')
const schemaAPICreator = require('../schemas/schemaAPI')
const wixDataProxyCreator = require('../wix-data/wixDataProxy')
const { createRecordStoreService } = require('../record-store')
const createControllerFactory = require('../dataset-controller/controllerFactory')

const createDatabindingVerboseReporter = require('../verbose/databindingVerboseReporter')
const traceActions = require('../logging/traceActions')

const extractDynamicUrlPatternFieldsValuesFromRecord = (dynamicUrl, record) => {
  const patternFields =
    dynamicUrl && record ? parseUrlPattern(dynamicUrl).fields : null
  return patternFields ? pick_(record, patternFields) : null
}

const extractRouterPayload = (payload, parser) => {
  const { dynamicUrl, userDefinedFilter, items, totalCount } = payload
  const parsedItems = parser(items)
  const record = parsedItems ? parsedItems[0] : null
  const dynamicUrlPatternFieldsValues = extractDynamicUrlPatternFieldsValuesFromRecord(
    dynamicUrl,
    record
  )

  return {
    prefetchedData: {
      items: parsedItems,
      totalCount
    },
    dynamicPagesData: {
      dynamicUrl,
      userDefinedFilter,
      dynamicUrlPatternFieldsValues
    }
  }
}

const mergeRouterDatasetConfig = (routerConfig, controllerConfig) =>
  merge_({}, controllerConfig, routerConfig)

const extractPlatformControllerAPI = ({ pageReady, exports }) => ({
  pageReady,
  exports
})

module.exports = ({
  wixDataCreator,
  elementorySupport,
  errorReporter,
  verboseReporter,
  shouldVerbose,
  appLogger,
  automationsClient
}) => {
  let wixDataProxy
  let wixDataSchemasProxy
  let routerPayload
  let platformUtilities
  let schemaAPI
  let wixSdk

  const completeControllerConfigs = controllerConfigs => {
    return controllerConfigs.map(controllerConfig => {
      const { config, type } = controllerConfig

      const mergedConfig =
        type === DATASET_TYPES.ROUTER_DATASET
          ? mergeRouterDatasetConfig(routerPayload.config, config)
          : config

      const datasetConfiguration = defaultsDeep_({}, mergedConfig, {
        dataset: defaultDatasetConfiguration
      })

      return Object.assign({}, controllerConfig, {
        config: datasetConfiguration
      })
    })
  }

  const startLoadingSchemas = controllerConfigs => {
    const prefetchedSchemaData = get_(
      controllerConfigs[0].warmupData,
      'schemas'
    )

    if (prefetchedSchemaData) {
      return schemaAPI.loadPrefetched(prefetchedSchemaData)
    }

    const collectionNames = getMainCollectionNames(controllerConfigs)

    return appLogger
      .traceAsync(traceActions.loadSchemas(), () =>
        schemaAPI.loadSchemas(collectionNames)
      )
      .catch(() => {})
  }

  const getMainCollectionNames = controllerConfigs => {
    const names = controllerConfigs.map(controllerConfig =>
      get_(controllerConfig, ['config', 'dataset', 'collectionName'], null)
    )
    return without_(uniq_(names), null)
  }

  const app = {
    initAppForPage: (
      { routerReturnedData },
      platformUtils,
      _wixSdk,
      { bi = {}, reportTrace = noop_ } = {}
    ) => {
      try {
        wixSdk = _wixSdk
        const inSsrMode =
          get_(wixSdk, ['window', 'rendering', 'env']) === 'backend'

        appLogger.addSessionData(() => ({ routerReturnedData }))
        appLogger.init({
          user: {
            id: get_(wixSdk, ['user', 'currentUser', 'id'])
          },
          inSsr: inSsrMode,
          viewMode: wixSdk.window.viewMode,
          platformBiParams: bi,
          browserUrlGetter: () => wixSdk.location.url,
          reportTrace
        })

        const traceId = appLogger.traceStart(traceActions.initAppForPage())
        ;({ wixDataProxy, wixDataSchemasProxy } = wixDataProxyCreator(
          appLogger.wixDataCodeZone,
          wixDataCreator
        ))
        routerPayload = routerReturnedData || {}
        platformUtilities = platformUtils
        schemaAPI = schemaAPICreator(wixDataSchemasProxy, appLogger)
        appLogger.traceEnd({ traceId })
        return Promise.resolve()
      } catch (e) {
        appLogger.error(e)
        return Promise.reject(e)
      }
    },

    createControllers: rawControllerConfigs => {
      const traceId = appLogger.traceStart(traceActions.createControllers())
      try {
        if (rawControllerConfigs.length === 0) {
          appLogger.traceEnd({ traceId })
          return []
        }

        const controllerConfigs = completeControllerConfigs(
          rawControllerConfigs
        )

        startLoadingSchemas(controllerConfigs)

        const isPreview = wixSdk.window.viewMode === 'Preview'
        const reportFormEventToAutomation = automationsClient.reportFormEventToAutomationCreator(
          {
            isPreview
          }
        )
        const datasetTypes = values_(DATASET_TYPES)
        const instansiateDatabindingVerboseReporter = createDatabindingVerboseReporter(
          verboseReporter,
          shouldVerbose
        )

        const controllers = controllerConfigs.map(
          ({
            type,
            config,
            connections,
            $w,
            compId: datasetId,
            warmupData
          }) => {
            if (!includes_(datasetTypes, type)) {
              throw new Error(
                `type of controller MUST be one of ${datasetTypes} but is ${type}`
              )
            }

            appLogger.breadcrumb({
              level: 'info',
              category: 'createControllers',
              message: 'warmup data contents',
              data: {
                datasetId,
                datasetType: type,
                env: wixSdk.window.rendering.env,
                warmupData: !!warmupData
              }
            })

            const routerData =
              type === DATASET_TYPES.ROUTER_DATASET
                ? extractRouterPayload(
                    routerPayload,
                    elementorySupport.wireFormatParser
                  )
                : undefined

            const recordStoreService = createRecordStoreService({
              wixDataProxy,
              warmupStore: get_(warmupData, 'store'),
              controllerConfig: config,
              logger: appLogger
            })

            const controllerFactory = createControllerFactory(appLogger, {
              controllerConfig: config,
              datasetType: type,
              connections,
              recordStoreService,
              wixDataProxy,
              firePlatformEvent: appLogger.userCodeZone($w.fireEvent),
              wixSdk,
              errorReporter,
              verboseReporter,
              instansiateDatabindingVerboseReporter,
              platformUtilities,
              routerData,
              appLogger,
              datasetId,
              handshakes: [],
              schemaAPI,
              reportFormEventToAutomation
            })

            const datasetController = extractPlatformControllerAPI(
              controllerFactory.createPrimaryController()
            )
            return Promise.resolve(datasetController)
          }
        )
        appLogger.traceEnd({ traceId })
        return controllers
      } catch (e) {
        appLogger.error(e)
        return []
      }
    }
  }

  return app
}
