'use strict'

const isError_ = require('lodash/isError')
const errorBoundaries = require('wix-error-boundaries')

const { isDatasetError } = require('../../dataset-api/DatasetError')
const errorBoundaryScopes = {
  USER_SCOPE: 'userCodeZone',
  APPLICATION_SCOPE: 'applicationCodeZone',
  WIX_DATA_SCOPE: 'wixDataCodeZone'
}
const WIX_DATA_USER_ERROR_CODES = [
  'WD_SITE_IN_TEMPLATE_MODE',
  'WD_PERMISSION_DENIED'
]
const WIX_DATA_UNKNOWN_ERROR = 'WD_UNKNOWN_ERROR'
const handled = Symbol('error was handled')

const isErrorHandled = e => e[handled]

const isUserError = (error, scope) =>
  isDatasetError(error.name) ||
  scope === errorBoundaryScopes.USER_SCOPE ||
  (scope === errorBoundaryScopes.WIX_DATA_SCOPE &&
    WIX_DATA_USER_ERROR_CODES.includes(error.code))

const isWixDataError = (error, scope) =>
  error.code === WIX_DATA_UNKNOWN_ERROR ||
  scope === errorBoundaryScopes.WIX_DATA_SCOPE

const reportException = (logFn, e, scope) => {
  logFn(e, { tags: { errorBoundaries: scope } })
  e[handled] = true
}

const dbsmViewerErrorHandler = (logFunctionsPerScope, e, scope) => {
  if (!(!isError_(e) || isErrorHandled(e))) {
    if (isUserError(e, scope)) {
      reportException(
        logFunctionsPerScope[errorBoundaryScopes.USER_SCOPE],
        e,
        scope
      )
    } else if (isWixDataError(e, scope)) {
      reportException(
        logFunctionsPerScope[errorBoundaryScopes.WIX_DATA_SCOPE],
        e,
        scope
      )
    } else if (scope === errorBoundaryScopes.APPLICATION_SCOPE) {
      reportException(
        logFunctionsPerScope[errorBoundaryScopes.APPLICATION_SCOPE],
        e,
        scope
      )
    }
  }
  throw e
}

const errorBoundariesCreator = logFunctionsPerScope => {
  return errorBoundaries({
    scopes: Object.values(errorBoundaryScopes),
    errorHandler: dbsmViewerErrorHandler.bind(undefined, logFunctionsPerScope)
  })
}

module.exports = errorBoundariesCreator
module.exports.isErrorHandled = isErrorHandled
module.exports.errorBoundaryScopes = errorBoundaryScopes
