'use strict'

const noop_ = require('lodash/noop')
const uuid = require('uuid')
const isString_ = require('lodash/isString')
const flow_ = require('lodash/flow')
const merge_ = require('lodash/merge')
const isError_ = require('lodash/isError')
const Result = require('folktale/result')
const { union } = require('folktale/adt/union')

const id = 'MONITORING_HUB'

const Environment = union('Environment', {
  NotInitialized() {},
  SSR({ fetch, elementorySupport, browserUrlGetter }) {
    const baseUrl =
      elementorySupport.baseUrl.replace(
        'wix-code-public-dispatcher/siteview',
        ''
      ) + 'cloud-monitoring-hub/v1'

    return { baseUrl, fetch, browserUrlGetter }
  },
  Client() {}
})

const tryOrGetUnknown = callback => Result.try(callback).getOrElse('unknown')

function sendRequest(fetch, baseUrl, data) {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  }

  return fetch(`${baseUrl}/logstash/events`, options)
}

function reportToMonitoringHub(fetch, baseUrl, params) {
  const transformedParams = transformParams(params)

  sendRequest(fetch, baseUrl, transformedParams).catch(() => {})
}

function stringifyMessage(params) {
  return !isString_(params.message)
    ? merge_({}, params, { message: JSON.stringify(params.message) })
    : params
}

function extractErrorInfo(params) {
  if (!isError_(params.message)) {
    return params
  }

  const error = params.message
  const newParams = {}
  if (error.stack) {
    newParams.stackTrace = error.stack
    newParams.message = error.message
  } else {
    newParams.message = error.toString()
  }

  return merge_({}, params, newParams)
}

function transformParams(params) {
  return flow_(extractErrorInfo, stringifyMessage)(params)
}

function getDefaultParams(browserUrlGetter) {
  return {
    source: 'dbsm-viewer-app',
    userActionId: uuid.v4(),
    message: '',
    params: {
      url: tryOrGetUnknown(browserUrlGetter),
      env: 'backend'
    }
  }
}

const traceStart = ({
  fetch,
  baseUrl,
  browserUrlGetter,
  actionName,
  level,
  params
}) => {
  const traceStartUnsafe = () => {
    const date = new Date()
    const extendedParams = merge_(
      {},
      getDefaultParams(browserUrlGetter),
      params,
      {
        action: actionName,
        level,
        timestamp: date.toJSON(),
        actionPosition: 'start'
      }
    )
    reportToMonitoringHub(fetch, baseUrl, extendedParams)
  }

  Result.try(traceStartUnsafe)
}

const traceEnd = ({
  fetch,
  baseUrl,
  browserUrlGetter,
  actionName,
  level,
  startParams,
  endParams,
  durationMs
}) => {
  const traceEndUnsafe = () => {
    const endDate = new Date()
    const duration = durationMs / 1000

    const extendedParams = merge_(
      {},
      getDefaultParams(browserUrlGetter),
      startParams,
      endParams,
      {
        action: actionName,
        level,
        timestamp: endDate.toJSON(),
        duration,
        actionPosition: 'end'
      }
    )

    reportToMonitoringHub(fetch, baseUrl, extendedParams)
  }

  Result.try(traceEndUnsafe)
}

const monitoringHubHandlerCreator = ({ elementorySupport, fetch }) => {
  let environment = Environment.NotInitialized()

  const monitoringHubHandler = () => ({
    id,
    init: ({ inSsr, browserUrlGetter }) => {
      environment = inSsr
        ? Environment.SSR({ elementorySupport, fetch, browserUrlGetter })
        : Environment.Client()
    },
    log: logEvent => {
      environment.matchWith({
        SSR: ({ fetch, baseUrl, browserUrlGetter }) => {
          logEvent.matchWith({
            TraceStart: ({ actionName, level, startParams: params }) => {
              traceStart({
                fetch,
                baseUrl,
                browserUrlGetter,
                actionName,
                level,
                params
              })
            },
            TraceEnd: ({
              actionName,
              level,
              startParams,
              endParams,
              durationMs
            }) => {
              traceEnd({
                fetch,
                baseUrl,
                browserUrlGetter,
                actionName,
                level,
                startParams,
                endParams,
                durationMs
              })
            },
            [union.any]: noop_
          })
        },
        NotInitialized: () => {
          throw new Error(
            `You cannot report to monitoring hub before setting the logger environment.
              Make sure you call logger.init before reporting.`
          )
        },
        Client: noop_
      })
    }
  })

  return monitoringHubHandler
}

module.exports.id = id
module.exports.monitoringHubHandlerCreator = monitoringHubHandlerCreator
