'use strict'
const Result = require('folktale/result')
const { ERROR } = require('@wix/dbsm-common/src/bi/biErrorCodes')
const isError_ = require('lodash/isError')
function safeLog({ biLogger, biPayload }) {
  Result.try(() => biLogger(biPayload))
}
module.exports = (biLogger, errorOrAny) => {
  if (isError_(errorOrAny)) {
    const exceptionMessage = errorOrAny.message
    const biPayload = {
      dsc: exceptionMessage,
      errn: exceptionMessage,
      errc: ERROR
    }
    safeLog({ biLogger, biPayload })
  } else {
    const message = Result.try(() => errorOrAny.toString()).getOrElse(
      errorOrAny
    )
    const biPayload = {
      errn: message,
      errc: ERROR
    }
    safeLog({ biLogger, biPayload })
  }
}
