'use strict'

const flow_ = require('lodash/flow')
const times_ = require('lodash/times')
const flatten_ = require('lodash/flatten')
const isEmpty_ = require('lodash/isEmpty')
const isEqual_ = require('lodash/isEqual')
const isPlainObject_ = require('lodash/isPlainObject')
const Maybe = require('folktale/maybe')
const Result = require('folktale/result')

const { breadcrumbWrapper } = require('../logger')
const sequence = require('@wix/dbsm-common/src/fp/sequence')
const readWriteModes = require('@wix/dbsm-common/src/dataset-configuration/readWriteModes')

const QueryResults = require('../helpers/queryResults')
const traceActions = require('../logging/traceActions')

const {
  cleanseRecord,
  createDraft,
  getRecordId,
  hasDraft,
  isRecordPristine
} = require('./records')
const {
  calculateMissingRange,
  freshScope,
  insertRecordAtIndex,
  overwriteRecordAtIndex,
  recordIndexById,
  removeRecordById,
  scopeHasRecord,
  setNewRecordMarkers,
  setSeedInScope,
  storeResultsInScope,
  updateNewRecordMarkers,
  updateNumMatchingRecords
} = require('./scopes')
const {
  clearDrafts,
  doesRecordExist,
  freshCollection,
  getDraftOrRecord,
  getScope,
  insertDraft,
  insertRecord,
  iterateScopes,
  readFromCollection,
  removeDraft,
  removeRecord,
  resetDraft,
  setScope,
  storeQueryResults,
  updateRecordFields,
  updateScope
} = require('./collections')
const {
  freshStore,
  fromWarmupStore,
  getCollection,
  setCollection,
  updateCollection
} = require('./store')
const { registrar } = require('./utils')

const WIXDATA_MAX_RECORD_LIMIT = 1000
const ignoreArgs = ['setFieldsValues', 'newRecord']

const serviceCreator = ({
  wixDataProxy,
  warmupStore,
  mainCollectionName,
  includes,
  readWriteType,
  logger
}) => {
  let theStore = isEmpty_(warmupStore)
    ? freshStore(mainCollectionName)
    : fromWarmupStore(warmupStore)
  const onChangeHandlers = []

  const service = ({
    pageSize,
    sort,
    filter,
    seed,
    datasetId,
    allowWixDataAccess,
    referencedCollectionName
  }) => {
    const collectionName =
      referencedCollectionName != null
        ? referencedCollectionName
        : mainCollectionName
    const scopeKey = JSON.stringify({ filter, sort })
    const getCurrentCollection = getCollection(collectionName)
    const updateCurrentCollection = updateCollection(collectionName)
    const getCurrentScope = flow_(getCurrentCollection, getScope(scopeKey))
    const updateCurrentScope = updateScope(scopeKey)

    // pageAlign :: Integer -> Integer
    const pageAlignedFromIndex = from => from - from % pageSize

    // pageAlign :: (Integer, Integer) -> Integer
    const pageAlignedToIndex = (from, to) =>
      Math.ceil(
        (pageAlignedFromIndex(from) + (to - pageAlignedFromIndex(from))) /
          pageSize
      ) * pageSize

    // findRecords :: (Integer, Integer) -> Promise QueryResults
    const findRecords = function(offset, length) {
      if (!allowWixDataAccess) {
        return Promise.resolve(QueryResults.Empty())
      }

      const traceId = logger.traceStart(
        traceActions.findRecords({
          collectionName,
          filter,
          sort,
          offset,
          length
        })
      )
      return wixDataProxy
        .find(
          collectionName,
          filter,
          sort,
          offset,
          length,
          undefined,
          referencedCollectionName != null ? undefined : includes
        )
        .then(wixDataQueryResults => {
          logger.traceEnd({ traceId })
          return QueryResults.fromWixDataQueryResults(
            wixDataQueryResults,
            offset
          )
        })
        .catch(e => {
          logger.traceEnd({
            traceId,
            level: logger.traceLevels.ERROR,
            params: { message: e }
          })
          return Promise.reject(e)
        })
    }

    // fetchMissingRanges :: (Scope, Integer, Integer) -> Promise ([QueryResults])
    const fetchMissingRange = (scope, fromIndex, toIndex) => {
      const fetchFrom = pageAlignedFromIndex(fromIndex)
      const fetchTo = pageAlignedToIndex(fromIndex, toIndex)
      const missingRange = calculateMissingRange(scope, fetchFrom, fetchTo)
      const correctedRange = missingRange.map(
        ({ from, length }) =>
          length <= WIXDATA_MAX_RECORD_LIMIT
            ? [{ from, length }]
            : flatten_(
                times_(Math.ceil(length / WIXDATA_MAX_RECORD_LIMIT), x => [
                  {
                    from: from + x * WIXDATA_MAX_RECORD_LIMIT,
                    length: Math.min(
                      WIXDATA_MAX_RECORD_LIMIT,
                      length - x * WIXDATA_MAX_RECORD_LIMIT
                    )
                  }
                ])
              )
      )

      return Promise.all(
        correctedRange
          .getOrElse([])
          .map(({ from, length }) => findRecords(from, length))
      )
    }

    const notify = (before, after, componentIdToExclude) =>
      sequence(
        Result,
        onChangeHandlers.map(handler =>
          Result.try(() =>
            handler(
              before != null ? cleanseRecord(before) : null,
              after != null ? cleanseRecord(after) : null,
              componentIdToExclude
            )
          )
        )
      )

    // runApiCommand
    //   :: (([Store -> Store] -> (), Record, Integer, [*]) -> *, () -> *, Integer, [*]) -> *
    const runApiCommand = (f, g, index, ...args) => {
      const recordId = getCurrentScope(theStore).records[index]
      const record = getDraftOrRecord(recordId, getCurrentCollection(theStore))

      if (record == null) {
        return g()
      } else {
        const update = (...flow) => {
          theStore = flow_(...flow)(theStore)
        }

        const notifyIfChanged = componentIdToExclude => {
          const updatedRecord = getDraftOrRecord(
            recordId,
            getCurrentCollection(theStore)
          )
          if (!isEqual_(record, updatedRecord)) {
            return notify(record, updatedRecord, componentIdToExclude)
          } else {
            return Result.Ok([])
          }
        }

        return f({ update, notifyIfChanged }, record, index, ...args)
      }
    }

    // withRecordByIndex
    //   :: (([Store -> Store] -> (), Record, Integer, [*]) -> *, () -> *) -> (Integer, [*]) -> *
    const withRecordByIndex = (f, g) => (index, ...args) => {
      return runApiCommand(f, g, index, ...args)
    }

    // withRecordByIndexAsync
    //   :: (([Store -> Store] -> (), Record, Integer, [*]) -> *, () -> *) -> (Integer, [*]) -> *
    const withRecordByIndexAsync = (f, g) => async (index, ...args) => {
      return runApiCommand(f, g, index, ...args)
    }

    // sanitise :: * -> *
    const sanitise = something => {
      const go = object =>
        Object.keys(object)
          .filter(key => key.startsWith('_'))
          .reduce(
            (acc, key) =>
              Object.assign(acc, {
                [key]: isPlainObject_(object[key])
                  ? go(object[key])
                  : object[key]
              }),
            {}
          )

      if (QueryResults.Results.hasInstance(something)) {
        return something.map(({ items }) => {
          const sanitisedItems = items.map(item => go(item))

          return { items: sanitisedItems }
        })
      } else if (typeof something === 'object' && something) {
        return go(something)
      } else {
        return something
      }
    }

    // createBreadcrumb :: (String, [*]) -> Breadcrumb
    const createBreadcrumb = (fnName, args = []) => ({
      category: 'recordStore',
      level: 'info',
      message: `${fnName}(${
        ignoreArgs.includes(fnName)
          ? `..${args.length} arguments..`
          : args.map(value => JSON.stringify(value)).join(', ')
      }) (${datasetId})`,
      data: {
        scope: scopeKey
      }
    })

    const { withBreadcrumbs, withBreadcrumbsAsync } = breadcrumbWrapper(
      logger,
      createBreadcrumb,
      sanitise
    )

    const isNewRecord = record =>
      record &&
      !doesRecordExist(getRecordId(record), getCurrentCollection(theStore))

    const api = {
      // getRecords :: (Integer, Integer) -> Promise QueryResults
      getRecords: withBreadcrumbsAsync(
        'getRecords',
        async (fromIndex, length) => {
          const totalMatchingRecords = getCurrentScope(theStore)
            .numMatchingRecords
          const toIndex =
            typeof totalMatchingRecords === 'number'
              ? Math.min(fromIndex + length, totalMatchingRecords)
              : fromIndex + length
          const reader = readFromCollection(scopeKey, fromIndex, toIndex)
          const isWriteOnly = readWriteType === readWriteModes.WRITE
          return reader(
            getCurrentCollection(theStore),
            isWriteOnly || totalMatchingRecords === 0
          ).orElse(async () => {
            const missingRange = await fetchMissingRange(
              getCurrentScope(theStore),
              fromIndex,
              toIndex
            )
            const notifyUpdatedRecords = (old, current) =>
              Object.keys(old.records)
                .filter(
                  key =>
                    isPlainObject_(current.records[key]) &&
                    current.records[key]._updatedDate >
                      old.records[key]._updatedDate
                )
                .forEach(key => notify(old.records[key], current.records[key]))
            const go = updateCurrentCollection(
              flow_(
                ...missingRange.map(range =>
                  flow_(
                    storeQueryResults(range),
                    updateCurrentScope(storeResultsInScope(range))
                  )
                )
              )
            )

            const oldStore = theStore
            theStore = go(theStore)
            notifyUpdatedRecords(
              getCurrentCollection(oldStore),
              getCurrentCollection(theStore)
            )
            return reader(getCurrentCollection(theStore), true)
          })
        }
      ),

      // seed :: () -> Promise ()
      seed: withBreadcrumbsAsync('seed', () => {
        if (getCurrentScope(theStore).numSeedRecords === 0) {
          return findRecords(0, pageSize).then(queryResult => {
            const go = updateCurrentCollection(
              flow_(
                storeQueryResults(queryResult),
                updateCurrentScope(setSeedInScope(queryResult))
              )
            )

            theStore = go(theStore)
          })
        } else {
          return Promise.resolve()
        }
      }),

      getTheStore: () => theStore,

      // getSeedRecords :: () -> QueryResults
      getSeedRecords: withBreadcrumbs('getSeedRecords', () =>
        readFromCollection(
          scopeKey,
          0,
          getCurrentScope(theStore).numSeedRecords,
          getCurrentCollection(theStore),
          true
        )
      ),

      // getTotalCount :: () -> Integer
      getMatchingRecordCount: withBreadcrumbs(
        'getMatchingRecordCount',
        () => getCurrentScope(theStore).numMatchingRecords || 0
      ),

      // getRecordById :: RecordId -> Maybe Record
      getRecordById: withBreadcrumbs('getRecordById', recordId => {
        return Maybe.fromNullable(
          getCurrentCollection(theStore).records[recordId]
        )
      }),

      // removeRecord :: Integer -> Promise (Result)
      // recordId is a maybe because new records don't have IDs
      removeRecord: withBreadcrumbsAsync(
        'removeRecord',
        withRecordByIndexAsync(
          async ({ update, notifyIfChanged }, record, index) => {
            const recordId = getRecordId(record)
            if (!isNewRecord(record) && recordId) {
              await wixDataProxy.remove(collectionName, recordId)
            }
            const doUpdateMarkers = recordIndex => markers =>
              markers.filter(marker => marker !== recordIndex)
            const updateScopesFlow = iterateScopes(
              (scope, scopeKey) =>
                updateScope(
                  scopeKey,
                  flow_(
                    removeRecordById(recordId),
                    updateNumMatchingRecords(x => (x != null ? x - 1 : null)),
                    updateNewRecordMarkers(
                      doUpdateMarkers(recordIndexById(recordId, scope))
                    )
                  )
                ),
              scopeHasRecord(recordId),
              getCurrentCollection(theStore)
            )
            update(
              updateCurrentCollection(
                flow_(
                  flow_(removeDraft(record), removeRecord(recordId)),
                  ...updateScopesFlow
                )
              )
            )
            return notifyIfChanged()
          },
          () => {
            return Promise.resolve(
              Result.Error('cannot remove record: index not found')
            )
          }
        )
      ),

      // reset :: () -> ()
      reset: withBreadcrumbs('reset', () => {
        theStore = updateCurrentCollection(
          flow_(setScope(scopeKey, freshScope()), clearDrafts())
        )(theStore)
      }),

      // newRecord :: (Integer, DefaultDraft) -> ()
      newRecord: withBreadcrumbs('newRecord', (index, defaultDraft) => {
        // There Can Only Be One new record
        const draft = createDraft(defaultDraft)
        const go = updateCurrentCollection(
          flow_(
            insertDraft(draft),
            updateCurrentScope(
              flow_(
                updateNumMatchingRecords(x => x + 1),
                setNewRecordMarkers([index]),
                insertRecordAtIndex(index, draft)
              )
            )
          )
        )

        theStore = go(theStore)
        notify(null, draft)
        return cleanseRecord(draft)
      }),

      // save :: Integer -> Promise(Record)
      saveRecord: withBreadcrumbsAsync(
        'saveRecord',
        withRecordByIndexAsync(
          async ({ update, notifyIfChanged }, record, index) => {
            const postSaveRecord = await wixDataProxy.save(
              collectionName,
              cleanseRecord(record),
              { includeReferences: true }
            )
            const doUpdateMarkers = markers =>
              markers.filter(marker => marker !== index)
            update(
              updateCurrentCollection(
                flow_(
                  insertRecord(postSaveRecord),
                  removeDraft(record),
                  updateCurrentScope(
                    flow_(
                      overwriteRecordAtIndex(index, postSaveRecord),
                      updateNewRecordMarkers(doUpdateMarkers)
                    )
                  )
                )
              )
            )
            notifyIfChanged()

            return cleanseRecord(postSaveRecord)
          },
          () => {
            return Promise.reject(
              new Error('cannot save record: index not found')
            )
          }
        )
      ),

      // setFieldsValues :: (Integer, FieldsValues) -> Result
      setFieldsValues: withBreadcrumbs(
        'setFieldsValues',
        withRecordByIndex(
          (
            { update, notifyIfChanged },
            record,
            _,
            fieldValues,
            componentIdToExclude
          ) => {
            if (Object.keys(fieldValues).length) {
              update(
                updateCurrentCollection(
                  updateRecordFields(getRecordId(record), fieldValues)
                )
              )
            }
            return notifyIfChanged(componentIdToExclude)
          },
          () => Result.Error('cannot update field values: index not found')
        )
      ),

      // isPristine :: Integer -> Boolean
      isPristine: withBreadcrumbs(
        'isPristine',
        withRecordByIndex((_, record) => isRecordPristine(record), () => true)
      ),

      // hasDraft :: Integer -> Boolean
      hasDraft: withBreadcrumbs(
        'hasDraft',
        withRecordByIndex((_, record) => hasDraft(record), () => false)
      ),

      // isNewRecord :: Integer -> Boolean
      isNewRecord: withBreadcrumbs(
        'isNewRecord',
        withRecordByIndex((_, record) => isNewRecord(record), () => true)
      ),

      clearDrafts: withBreadcrumbs('clearDrafts', () => {
        theStore = updateCurrentCollection(clearDrafts())(theStore)
      }),

      // resetDraft :: (Integer, DefaultDraft) -> Result
      resetDraft: withBreadcrumbs(
        'resetDraft',
        withRecordByIndex(
          ({ update, notifyIfChanged }, record, index, defaultDraft) => {
            update(
              updateCurrentCollection(
                isNewRecord(record)
                  ? resetDraft(record, defaultDraft)
                  : removeDraft(record)
              )
            )
            return notifyIfChanged()
          },
          () => Result.Error('cannot reset draft: index not found')
        )
      ),

      hasSeedData: withBreadcrumbs(
        'hasSeedData',
        () => getCurrentScope(theStore).numSeedRecords > 0
      )
    }

    if (!getCurrentCollection(theStore)) {
      theStore = setCollection(collectionName, freshCollection())(theStore)
    }

    if (!getCurrentCollection(theStore).scopes[scopeKey]) {
      const initScope = []
      seed.map(seedData => initScope.push(storeQueryResults(seedData)))
      initScope.push(setScope(scopeKey, freshScope()))
      seed.map(seedData =>
        initScope.push(updateCurrentScope(setSeedInScope(seedData)))
      )
      theStore = updateCurrentCollection(flow_(...initScope))(theStore)
    }

    return api
  }

  service.onChange = registrar(onChangeHandlers)

  return service
}

module.exports = serviceCreator
