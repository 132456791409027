'use strict'

const cloneDeep_ = require('lodash/cloneDeep')
const mapValues_ = require('lodash/mapValues')
const identity_ = require('lodash/identity')
const flow_ = require('lodash/flow')
const noop_ = require('lodash/noop')

const datasetActions = require('../dataset-controller/actions')
const routerDatasetApiCreator = require('./routerDatasetApi')
const recordActions = require('../records/actions')
const configActions = require('../dataset-config/actions')
const { performHandshake } = require('../dependency-resolution/actions')
const reducer = require('../dataset-controller/rootReducer')
const DatasetError = require('./DatasetError')
const {
  READ,
  WRITE,
  READ_WRITE
} = require('@wix/dbsm-common/src/dataset-configuration/readWriteModes')
const { breadcrumbWrapper } = require('../logger')
const {
  assertDatasetLimitations,
  assertDatasetReady,
  assertHasCurrentItem,
  assertValidIndex,
  assertValidNumberArgument,
  assertValidCallback,
  assertValidFilter,
  assertValidSort,
  assertScopeIsNotFixedItem,
  assertValidPageIndex,
  assertValidNaturalNumber,
  assetValidHandshakeInfo
} = require('./datasetApiAssertions')
const reportCallbackError = require('../logging/reportCallbackError')
const { verboseWrapper } = require('./verbosity')

const datasetApiCreator = ({
  store: { dispatch, getState },
  recordStore,
  logger,
  eventListeners: { fireEvent, register },
  handshakes,
  controllerStore,
  errorReporter,
  verboseReporter,
  datasetId,
  datasetType,
  isForUser,
  isFixedItem,
  siblingDynamicPageUrlGetter,
  dependenciesManager
}) => {
  let q = Promise.resolve()
  const queue = f =>
    new Promise((resolve, reject) => {
      q = q.then(() =>
        Promise.resolve()
          .then(f)
          .then(resolve)
          .catch(reject)
      )
    })

  const createBreadcrumb = (fnName, args) => ({
    category: 'datasetAPI',
    level: 'info',
    message: `${fnName}-${datasetId}`
  })
  const { withBreadcrumbs } = breadcrumbWrapper(
    logger,
    createBreadcrumb,
    value => undefined // we only want to trace user calls, no need for return value
  )

  const addBreadcrumb = functionName => fn => withBreadcrumbs(functionName, fn)

  const fireErrorEvent = (operation, e) => {
    fireEvent('datasetError', operation, e)
  }

  const getCurrentIndex = () => reducer.selectCurrentRecordIndex(getState())
  const getTotalCount = () =>
    recordStore().matchWith({
      Error: () => null,
      Ok: ({ value: service }) => service.getMatchingRecordCount()
    })

  const flushDraft = async function() {
    try {
      await dispatch(recordActions.flushDraft())
      // the save process must return the record as it was returned from wixData, ignoring any
      // changes made to it afterwards (e.g., after save callback). If there was no draft to flush,
      // it must return the current record.
      const updatedRecord =
        reducer.selectLastSavedRecord(getState()) ||
        reducer.selectCurrentRecord(getState())
      return cloneDeep_(updatedRecord)
    } catch (e) {
      fireErrorEvent('save', e)
      throw e
    }
  }

  /**
   * @namespace wix-dataset
   * @summary A dataset connects page elements to a set of items in a data collection.
   * @description
   *  A dataset serves as an intermediary between page elements, such as input
   *  elements and buttons, and the data in a collection. The dataset
   *  controls which collection is available to be used by page elements, what
   *  those elements can do with the collection data (display, add, modify),
   *  which item is currently active and whether the data is filtered or sorted.
   *
   *  A dataset can be in one of three modes, which are set in Dataset Settings
   *  panel in the Editor. The dataset's mode cannot be changed programmatically.
   *
   *    + **Read & Write**: Display and modify data.
   *    + **Read-only**: Display data.
   *    + **Write-only**: Add new data.
   *
   *
   *  ![Dataset modes](images/dataset_modes.png "Dataset modes")
   *
   *  Another way to work with collection data and page elements is to use the
   *  [Data API](wix-data.html). The Data API lets you work directly with your
   *  collections. However, you'll need to write code to read from and write to
   *  your page elements. Using the Dataset API allows you to take advantage of
   *  the [data binding](https://support.wix.com/en/article/data-binding) that
   *  can be set up in the Editor's connect panels.
   *
   *  The wix-dataset API can only be used in your site’s front-end code.
   *
   *  You do not need to import `wix-dataset`.
   *
   *  <h3 id="data-paging">Dataset Pages</h3>
   *  Datasets retrieve information from your collections in chunks of items.
   *  Each of these chunks is called a page.
   *
   *  A dataset's page size determines how many items are initially displayed
   *  in repeaters that are connected to it. Elements that have their own
   *  settings for how many items are shown at once, such as tables and
   *  galleries, are not affected by the dataset's page size.
   *
   *  Elements that display data from the dataset's current item, such as images
   *  and text elements, are affected when you change the current dataset page
   *  because the dataset's current item also changes.
   *
   *  You set the page size of a dataset:
   *
   *  + In the Editor using the **Number of items to display** setting in the **Dataset Settings** panel.
   *  + In code using the [`setPageSize()`](#setPageSize) function.
   *
   *
   */

  /**
   * @class Dataset
   * @summary A [dataset](wix-dataset.html) connects page elements to a set of items in a data collection.
   * @memberof wix-dataset
   * @definitionId dataBinding.dataset
   */

  return isForUser => {
    const userCodeZone = isForUser ? logger.userCodeZone : identity_

    const baseApi = {
      async isIdle() {
        await queue(noop_)
      },

      /**
       * @callback BeforeSaveHandler
       * @summary A before save event handler.
       * @returns {Promise}
       * @fulfill {external:Boolean} Indication whether to continue with save.
       * @memberof wix-dataset.Dataset
       **/

      /**
       * @function onBeforeSave
       * @syntax
       * function onBeforeSave(handler: BeforeSaveHandler): void
       * callback BeforeSaveHandler(): Promise<boolean>
       * @summary Adds an event handler that runs just before a save.
       * @param {wix-dataset.Dataset.BeforeSaveHandler} handler The before save event handler.
       * @description
       *  The `onBeforeSave()` function allows you to optionally perform actions
       *  right before a [`save()`](#save) operation. When you call `save()`, the
       *  callback is run before the save operation. If the callback function
       *  returns either `false`, a rejected Promise, or it throws an error, the
       *  save operation is canceled.
       *
       *  Calling `onBeforeSave()` on a read-only dataset causes an error.
       * @snippet [onBeforeSave_continue.es6=Register a callback to run before a save and continue with the save]
       * @snippet [onBeforeSave_cancel.es6=Register a callback to run before a save and cancel the save]
       * @memberof wix-dataset.Dataset
       * @instance
       */
      onBeforeSave: cb => {
        assertValidCallback('onBeforeSave', cb)
        assertDatasetLimitations(
          getState,
          'onBeforeSave',
          [WRITE, READ_WRITE],
          false
        )
        return register('beforeSave', userCodeZone(cb))
      },

      /**
       * @callback AfterSaveHandler
       * @summary An after save event handler.
       * @param {external:Object} itemBeforeSave The item before being saved.
       * @param {external:Object} itemAfterSave The item after being saved.
       * @returns {void}
       * @memberof wix-dataset.Dataset
       **/

      /**
       * @function onAfterSave
       * @syntax
       * function onAfterSave(handler: AfterSaveHandler): void
       * callback AfterSaveHandler(itemBeforeSave: Object, itemAfterSave: Object): void
       * @summary Adds an event handler that runs just after a save.
       * @param {wix-dataset.Dataset.AfterSaveHandler} handler The after save event handler.
       * @description
       *  The `onAfterSave()` function allows you to optionally perform actions
       *  right after a [`save()`](#save) operation. When you call `save()`, the
       *  callback is run after the save has successfully completed.
       *
       *  Calling `onAfterSave()` on a read-only dataset causes an error.
       * @snippet [onAfterSave.es6=Register a callback to run after a save]
       * @memberof wix-dataset.Dataset
       * @instance
       */
      onAfterSave: cb => {
        assertValidCallback('onAfterSave', cb)
        assertDatasetLimitations(
          getState,
          'onAfterSave',
          [WRITE, READ_WRITE],
          false
        )
        return register('afterSave', userCodeZone(cb))
      },

      /**
       * @function save
       * @memberof wix-dataset.Dataset
       * @summary Saves the current item.
       * @syntax
       * function save(): Promise<Object>
       * @description
       *  The `save()` function returns a Promise that is resolved to the saved item
       *  when:
       *
       *  + The current item is saved in the collection.
       *  + Any connected page elements have been updated with the current item’s new
       *    values (read &amp; write mode) or a new blank item (write-only mode).
       *
       *
       *  Calling `save()` on a read-only dataset causes the Promise to reject.
       *
       * @note
       *  A dataset needs to load its data before you call its `save()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `save()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `save()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       * @snippet [save.es6=Save the current item]
       * @snippet [save_onReady.es6=Save the current item when the page loads]
       * @returns {Promise}
       * @fulfill {external:Object} The saved item.
       * @reject {String} An error message.
       * @see [revert( )](#revert)
       * @see [refresh( )](#refresh)
       * @instance
       */
      async save() {
        assertDatasetLimitations(getState, 'save', [WRITE, READ_WRITE], false)
        return queue(async () => {
          const updatedRecord = await flushDraft()
          if (reducer.isWriteOnly(getState())) {
            await dispatch(recordActions.reInitWriteOnly())
          }
          return updatedRecord
        })
      },

      /**
       * @typedef wix-dataset.Dataset~GetItemsResult
       * @summary An object used by the `getItems()` function that contains the items retrieved and the total number of items in the dataset that match its filter criteria
       * @property {external:Object[]} items List of items objects where key:value pairs are the field keys and field values of the retrieved items, including all hidden fields.
       * @property {external:Number} totalCount The number of items in the dataset that match its filter criteria.
       * @property {external:Number} offset The index in the dataset of the first item in the items property.
       * @snippet [getItems.es6=Get items from the dataset]
       * @see [getItems( )](#getItems)
       */

      /**
       * @function getItems
       * @memberof wix-dataset.Dataset
       * @syntax
       * function getItems(fromIndex: Number, numberOfItems: Number):
       *   Promise<GetItemsResult>
       * @summary Returns the selected items.
       * @description
       *  The `getItems()` function returns a Promise that is resolved to
       *  a [`GetItemsResult`](#GetItemsResult) object when the items have been
       *  retrieved.
       *
       *  Calling `getItems()` on a write-only dataset causes an error.
       *
       * @note
       *  A dataset needs to load its data before you call its `getItems()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `getItems()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `getItems()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       * @param {external:Number} fromIndex The index of the first item to return.
       * @param {external:Number} numberOfItems The number of items to return.
       * @returns {Promise}
       * @fulfill {wix-dataset.Dataset~GetItemsResult} The items retrieved and the total number of items in the dataset that match its filter criteria.
       * @reject {external:Error}  An error object.
       * @snippet [getItems.es6=Get items from the dataset]
       * @snippet [getItems_onReady.es6=Get items from the dataset when the page loads]
       * @see [getCurrentItem( )](#getCurrentItem)
       */
      async getItems(fromIndex, numberOfItems) {
        assertDatasetLimitations(
          getState,
          'getItems',
          [READ, READ_WRITE],
          false
        )
        assertValidNumberArgument('fromIndex', fromIndex)
        assertValidNumberArgument('numberOfItems', numberOfItems)
        try {
          return await recordActions.doFetch(
            recordStore,
            fromIndex,
            numberOfItems
          )
        } catch (e) {
          fireErrorEvent('getItems', e)
          throw e
        }
      },

      /**
       * @function getTotalCount
       * @memberof wix-dataset.Dataset
       * @syntax
       * function getTotalCount(): Number
       * @summary Returns the number of items in the dataset that match its filter criteria.
       * @description
       *  Calling `getTotalCount()` on a write-only dataset causes an error.
       *
       * @note
       *  A dataset needs to load its data before you call its `getTotalCount()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `getTotalCount()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `getTotalCount()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       * @snippet [getTotalCount.es6=Get the number of items in the dataset that match its filter criteria]
       * @snippet [getTotalCount_onReady.es6=Get the number of items in the dataset that match its filter criteria when the page loads]
       * @returns {external:Number}
       */
      getTotalCount: () => {
        assertDatasetLimitations(
          getState,
          'getTotalCount',
          [READ, READ_WRITE],
          false
        )
        return getTotalCount()
      },

      /**
       * @function getCurrentItem
       * @memberof wix-dataset.Dataset
       * @summary Returns the current item.
       * @syntax
       * function getCurrentItem(): Object
       * @returns {external:Object} The current item, or null if there is no current item.
       * @description
       *  The `getCurrentItem()` function returns an object whose key:value pairs
       *  are the field keys and field values of the current item, including all
       *  hidden fields. Fields that do not have a value are omitted from the
       *  returned object.
       *
       *  When called on a write-only or read &amp; write dataset,
       *  `getCurrentItem()` returns the unsaved state of the current item.
       *
       *  Returns `null` or `undefined` if the dataset:
       *
       *  + Is filtered to not match any items in the collection.
       *  + Is empty.
       *  + Has not loaded yet.
       *
       *
       * @note
       *  A dataset needs to load its data before you call its `getCurrentItem()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `getCurrentItem()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `getCurrentItem()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       *
       *  If the current item is changed from a non-dataset source, such as by
       *  selecting a row in a table connected to a dataset or clicking a button set
       *  to advance to the next item in a dataset, the dataset's current item is
       *  not updated immediately. Therefore, to get the dataset's new current item,
       *  call `getCurrentItem()` in the dataset's [`onCurrentIndexChanged()`](#onCurrentIndexChanged)
       *  event handler. Do not use the events of the non-dataset source, such as
       *  [`onRowSelect`]($w.Table.html#onRowSelect) or [`onClick`]($w.ClickableMixin.html#onClick),
       *  to call `getCurrentItem()` because they will fire before the dataset's
       *  current item is updated.
       * @snippet [getCurrentItem.es6=Get the dataset's current item]
       * @snippet [getCurrentItem_onReady.es6=Get the dataset's current item when the page loads]
       * @see [getCurrentItemIndex( )](#getCurrentItemIndex)
       * @see [setCurrentItemIndex( )](#setCurrentItemIndex)
       */
      getCurrentItem: () => {
        assertDatasetLimitations(getState, 'getCurrentItem', [
          READ,
          WRITE,
          READ_WRITE
        ])
        const record = reducer.selectCurrentRecord(getState())
        if (!record) {
          return null
        }
        return cloneDeep_(record)
      },

      /**
       * @function getCurrentItemIndex
       * @memberof wix-dataset.Dataset
       * @summary Returns the current item's index.
       * @syntax
       * function getCurrentItemIndex(): Number
       * @returns {external:Number} The index of the current item, or null if there is no current item.
       * @description
       *  The `getCurrentItemIndex()` function returns the index of current item
       *  within the items of the dataset. The indices of the items in a dataset
       *  are zero-based. For example, if the third item is the current item, then
       *  `getCurrentItemIndex()` returns `2`.
       *
       *  Calling `getCurrentItemIndex()` on a write-only dataset causes an error.
       *
       *  Returns `null` if one of the following is true if the dataset:
       *
       *  + Is filtered to not match any items in the collection.
       *  + Is empty.
       *  + Has not loaded yet.
       *
       *
       * @note
       *  A dataset needs to load its data before you call its `getCurrentItemIndex()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `getCurrentItemIndex()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `getCurrentItemIndex()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       *
       *  If the current item is changed from a non-dataset source, such as by
       *  selecting a row in a table connected to a dataset or clicking a button set
       *  to advance to the next item in a dataset, the dataset's current item is
       *  not updated immediately. Therefore, to get the dataset's new current item index,
       *  call `getCurrentItemIndex()` in the dataset's [`onCurrentIndexChanged()`](#onCurrentIndexChanged)
       *  event handler. Do not use the events of the non-dataset source, such as
       *  [`onRowSelect`]($w.Table.html#onRowSelect) or [`onClick`]($w.ClickableMixin.html#onClick),
       *  to call `getCurrentItemIndex()` because they will fire before the dataset's
       *  current item is updated.
       * @see [setCurrentItemIndex( )](#setCurrentItemIndex)
       * @see [getCurrentItem( )](#getCurrentItem)
       */
      getCurrentItemIndex: () => {
        assertDatasetLimitations(getState, 'getCurrentItemIndex', [
          READ,
          READ_WRITE
        ])
        const index = reducer.selectCurrentRecordIndex(getState())
        if (index === undefined) {
          return null
        }
        return index
      },

      /**
       * @function setCurrentItemIndex
       * @memberof wix-dataset.Dataset
       * @summary Sets the current item by index.
       * @syntax
       * function setCurrentItemIndex(index: Number): Promise<void>
       * @description
       *  The `setCurrentItemIndex()` function returns a Promise that is resolved when:
       *
       *  + The current item has been saved in the collection (if necessary).
       *  + The current item has been updated to be the item with the given index.
       *  + Any connected page elements have been updated with the new current item’s values.
       *
       *
       *  Calling `setCurrentItemIndex()` on a write-only dataset causes the
       *  Promise to reject.
       *
       * @note
       *  A dataset needs to load its data before you call its `setCurrentItemIndex()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `setCurrentItemIndex()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `setCurrentItemIndex()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       *
       *  `setCurrentItemIndex()` saves the current item even if the requested item is same as the current item.
       * @param {external:Number} index The index of the item to set as the current item.
       * @returns {Promise}
       * @fulfill {void} When the item with the given index is set to the current item.
       * @reject {external:Error} Rejects if the item with the given index does not exist or it cannot be set to the current item.
       * @snippet [setCurrentItemIndex.es6=Set the dataset's current item]
       * @snippet [setCurrentItemIndex_onReady.es6=Set the dataset's current item when the page loads]
       * @see [getCurrentItemIndex( )](#getCurrentItemIndex)
       * @see [getCurrentItem( )](#getCurrentItem)
       */
      async setCurrentItemIndex(index) {
        assertScopeIsNotFixedItem(isFixedItem, 'setCurrentItemIndex')
        assertDatasetLimitations(
          getState,
          'setCurrentItemIndex',
          [READ, READ_WRITE],
          false
        )
        assertValidIndex(index)

        await new Promise(resolve => api.onReady(resolve))

        try {
          await queue(async () => {
            if (!reducer.isReadOnly(getState())) {
              await flushDraft()
            }
            await dispatch(recordActions.setCurrentIndex(index))
          })
        } catch (e) {
          fireErrorEvent('setCurrentItemIndex', e)
          throw e
        }
      },

      /**
       * @function setFieldValue
       * @memberof wix-dataset.Dataset
       * @summary Updates the value of a field in the current item.
       * @syntax
       * function setFieldValue(fieldKey: string, value: *): void
       * @param {external:String} fieldKey The field key of the field to update.
       * @param {*} value The new value.
       * @description
       *  The `setFieldValue` function sets the value of a field in the current item.
       *  Setting a field value fires an `onItemValuesChanged` event when the page
       *  elements connected to the field have been updated with the new value.
       *
       *  Setting the value of a field in a dataset item does not immediately set
       *  that value in the collection that the dataset is connected to. You still
       *  need to call the dataset `save()` function or any other function that
       *  performs a save to have the new value reflecting in the collection.
       *
       *  Calling `setFieldValue()` on a read-only dataset causes an error.
       *
       * @note
       *  A dataset needs to load its data before you call its `setFieldValue()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `setFieldValue()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `setFieldValue()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       * @snippet [setFieldValue.es6=Set a field's value]
       * @snippet [setFieldValue_save.es6=Set a field's value and save the item to the connected collection]
       * @snippet [setFieldValue_onReady.es6=Set a field's value when the page loads]
       * @see [setFieldValues( )](#setFieldValues)
       */
      setFieldValue: (fieldName, value) => {
        assertDatasetReady(getState, 'setFieldValue')
        assertDatasetLimitations(getState, 'setFieldValue', [WRITE, READ_WRITE])
        assertHasCurrentItem(getState)
        dispatch(recordActions.updateFields({ [fieldName]: cloneDeep_(value) }))
      },

      /**
       * @function setFieldValues
       * @memberof wix-dataset.Dataset
       * @summary Updates the values of a set of fields in the current item.
       * @syntax
       * function setFieldValues(fieldValues: Object): void
       * @param {external:Object} fieldValues A map of field keys to new values.
       * @description
       *  The `setFieldValues` function sets the value of a set of fields in the current item.
       *  Setting the field values fires one `onItemValuesChanged` event when the page
       *  elements connected to the fields have been updated with the new values.
       *
       *  Calling `setFieldValues()` on a read-only dataset causes an error.
       *
       * @note
       *  A dataset needs to load its data before you call its `setFieldValues()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `setFieldValues()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `setFieldValues()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       * @snippet [setFieldValues.es6=Set several fields' values]
       * @snippet [setFieldValues_onReady.es6=Set several fields' values when the page loads]
       * @see [setFieldValue( )](#setFieldValue)
       */
      setFieldValues: fieldValues => {
        assertDatasetReady(getState, 'setFieldValues')
        assertDatasetLimitations(getState, 'setFieldValues', [
          WRITE,
          READ_WRITE
        ])
        assertHasCurrentItem(getState)
        dispatch(
          recordActions.updateFields(mapValues_(fieldValues, cloneDeep_))
        )
      },

      /**
       * @function next
       * @memberof wix-dataset.Dataset
       * @summary Saves the current item and moves to the next item.
       * @syntax
       * function next(): Promise<Object>
       * @description
       *  The `next()` function returns a Promise that is resolved to the next item
       *  when:
       *
       *  + The current item is saved in the collection (if necessary).
       *  + Any connected page elements have been updated with the new current item’s values.
       *
       *
       *  Calling `next()` on a write-only dataset causes the
       *  Promise to reject.
       *
       *  If the dataset is read-write, the current item is saved even if there is
       *  no next item.
       *
       * @note
       *  A dataset needs to load its data before you call its `next()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `next()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `next()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       * @snippet [next.es6=Move to the next item]
       * @snippet [next_onReady.es6=Move to the next item when the page loads]
       * @returns {Promise}
       * @fulfill {external:Object} The next item in the dataset.
       * @reject {String} An error message.
       * @see [previous( )](#previous)
       * @see [hasNext( )](#hasNext)
       */
      async next() {
        assertScopeIsNotFixedItem(isFixedItem, 'next')
        assertDatasetReady(getState, 'next')
        assertDatasetLimitations(getState, 'next', [READ, READ_WRITE], false)
        try {
          return await queue(async () => {
            if (!reducer.isReadOnly(getState())) {
              await flushDraft()
            }
            if (!api.hasNext()) {
              throw new DatasetError(
                'NO_SUCH_ITEM',
                'There are no more items in the dataset'
              )
            }
            await dispatch(recordActions.setCurrentIndex(getCurrentIndex() + 1))
            return api.getCurrentItem()
          })
        } catch (e) {
          fireErrorEvent('next', e)
          throw e
        }
      },

      /**
       * @function previous
       * @memberof wix-dataset.Dataset
       * @summary Saves the current item and moves to the previous item.
       * @syntax
       * function previous(): Promise<Object>
       * @description
       *  The `previous()` function returns a Promise that is resolved to the previous item
       *  when:
       *
       *  + The current item is saved in the collection (if necessary).
       *  + Any connected page elements have been updated with the new current item’s values.
       *
       *
       *  Calling `previous()` on a write-only dataset causes the
       *  Promise to reject.
       *
       *  If the dataset is read-write, the current item is saved even if there is
       *  no previous item.
       *
       * @note
       *  A dataset needs to load its data before you call its `previous()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `previous()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `previous()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       * @snippet [previous.es6=Move to the previous item]
       * @snippet [previous_onReady.es6=Move to the previous item when page loads]
       * @returns {Promise}
       * @fulfill {external:Object} The previous item in the dataset.
       * @reject {String} An error message.
       * @see [next( )](#next)
       * @see [hasPrevious( )](#hasPrevious)
       */
      async previous() {
        assertScopeIsNotFixedItem(isFixedItem, 'previous')
        assertDatasetReady(getState, 'previous')
        assertDatasetLimitations(
          getState,
          'previous',
          [READ, READ_WRITE],
          false
        )
        try {
          return await queue(async () => {
            if (!reducer.isReadOnly(getState())) {
              await flushDraft()
            }
            if (!api.hasPrevious()) {
              throw new DatasetError(
                'NO_SUCH_ITEM',
                'This is the first item in the dataset'
              )
            }
            await dispatch(recordActions.setCurrentIndex(getCurrentIndex() - 1))
            return api.getCurrentItem()
          })
        } catch (e) {
          fireErrorEvent('previous', e)
          throw e
        }
      },

      /**
       * @function hasNext
       * @memberof wix-dataset.Dataset
       * @summary Indicates if there is a next item.
       * @syntax
       * function hasNext(): boolean
       * @returns {external:Boolean}
       * @description
       *  Returns `true` if the current item is not the last item in the dataset.
       *
       *  Returns `false` if the current item is the last item in the dataset.
       *
       *  Calling `hasNext()` on a write-only dataset causes an error.
       *
       * @note
       *  A dataset needs to load its data before you call its `hasNext()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `hasNext()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `hasNext()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       * @snippet [hasNext.es6=Get whether the current item is the last item]
       * @snippet [hasNext_onReady.es6=Get whether the current item is the last item when the page loads]
       * @see [hasPrevious( )](#hasPrevious)
       */
      hasNext: () => {
        assertDatasetLimitations(getState, 'hasNext', [READ, READ_WRITE])
        const currentRecordIndex = reducer.selectCurrentRecordIndex(getState())
        const totalCount = getTotalCount()
        return (
          currentRecordIndex != null &&
          totalCount != null &&
          currentRecordIndex < totalCount - 1
        )
      },

      /**
       * @function hasPrevious
       * @memberof wix-dataset.Dataset
       * @summary Indicates if there is a previous item.
       * @syntax
       * function hasPrevious(): boolean
       * @returns {external:Boolean}
       * @description
       *  Returns `true` if the current item is not the first item in the dataset.
       *
       *  Returns `false` if the current item is the first item in the dataset.
       *
       *  Calling `hasPrevious()` on a write-only dataset causes an error.
       *
       * @note
       *  A dataset needs to load its data before you call its `hasPrevious()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `hasPrevious()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `hasPrevious()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       * @snippet [hasPrevious.es6=Get whether the current item is the first item]
       * @snippet [hasPrevious_onReady.es6=Get whether the current item is the first item when the page loads]
       * @see [hasNext( )](#hasPrevious)
       */
      hasPrevious: () => {
        assertDatasetLimitations(getState, 'hasPrevious', [READ, READ_WRITE])
        const currentRecordIndex = reducer.selectCurrentRecordIndex(getState())
        return currentRecordIndex != null && currentRecordIndex > 0
      },

      /**
       * @function new
       * @memberof wix-dataset.Dataset
       * @syntax
       * function new(): Promise<void>
       * @summary Create a new blank item.
       * @description
       *  The `new()` function saves the current item and then creates a new blank
       *  item. When the editing of the new item is complete, you will need to
       *  call another function that will save the new item.
       *
       *  The index of the new item is one after the index of the current item, or
       *  zero if there is no current item.
       *
       *  Note that since the `new()` function begins by saving the current item,
       *  if the current item cannot be saved for any reason, such as it does not
       *  pass validation, calling the function will cause an error.
       *
       *  Calling `new()` on a read-only dataset causes an error.
       *
       * @note
       *  A dataset needs to load its data before you call its `new()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `new()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `new()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       * @snippet [new.es6=Save the current item and create a new blank item]
       * @snippet [new_onReady.es6=Save the current item and create a new blank item when the page loads]
       * @returns {Promise}
       * @fulfill {void} When a new empty item is the current item of the dataset.
       * @reject {String} An error message.
       */
      async new(atIndex) {
        assertDatasetReady(getState, 'new')
        assertDatasetLimitations(getState, 'new', [WRITE, READ_WRITE], false)
        try {
          await queue(async () => {
            await flushDraft()
            const currentIndex = getCurrentIndex()
            const newIndex =
              atIndex != null
                ? atIndex
                : currentIndex == null
                  ? 0
                  : currentIndex + 1

            const totalCount = getTotalCount()
            if (newIndex < 0 || (totalCount != null && newIndex > totalCount)) {
              throw new DatasetError('DS_INDEX_OUT_OF_RANGE', 'Invalid index')
            }

            await dispatch(recordActions.newRecord(newIndex))
          })
        } catch (e) {
          fireErrorEvent('new', e)
          throw e
        }
      },

      /**
       * @function remove
       * @memberof wix-dataset.Dataset
       * @summary Removes the current item.
       * @syntax
       * function remove(): Promise<void>
       * @description
       *  The `remove()` function returns a Promise that is resolved
       *  when:
       *
       *  + The current item is deleted in the collection.
       *  + Any connected page elements have been updated with the next item’s
       *    values if there is a next item, or the previous item's values if the
       *    removed item was the last item.
       *
       *
       *  Calling `remove()` on a write-only or read-only dataset causes an error.
       *
       * @note
       *  A dataset needs to load its data before you call its `remove()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `remove()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `remove()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       * @snippet [remove.es6=Remove the current item]
       * @snippet [remove_onReady.es6=Remove the current item when the page loads]
       * @returns {Promise}
       * @fulfill {void} When the current item has been removed from the collection.
       * @see [revert( )](#revert)
       * @see [refresh( )](#refresh)
       */
      async remove() {
        assertDatasetReady(getState, 'remove')
        assertDatasetLimitations(getState, 'remove', [READ_WRITE], false)
        try {
          await queue(async () => {
            const index = getCurrentIndex()
            if (index == null) {
              throw new DatasetError('DS_INDEX_OUT_OF_RANGE', 'Invalid index')
            }
            await dispatch(recordActions.remove())
          })
        } catch (e) {
          fireErrorEvent('remove', e)
          throw e
        }
      },

      /**
       * @function revert
       * @memberof wix-dataset.Dataset
       * @summary Reverts the current item to its saved value.
       * @syntax
       * function revert(): Promise<void>
       * @description
       *  The `revert()` function returns a Promise that is resolved
       *  when:
       *
       *  + The current item is reverted to its saved state in the collection.
       *  + Any connected page elements have been updated with the current item’s
       *    old values (read &amp; write mode) or blank values (write-only mode).
       *
       *
       *  Calling `revert()` on a read-only dataset causes the Promise to reject.
       *
       * @note
       *  A dataset needs to load its data before you call its `revert()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `revert()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `revert()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       * @snippet [revert.es6=Revert the current item]
       * @snippet [revert_onReady.es6=Revert the current item when the page loads]
       * @returns {Promise}
       * @fulfill {void} When the item has been reverted to its saved state.
       * @reject {String} An error message.
       * @see [refresh( )](#refresh)
       * @see [save( )](#save)
       */
      async revert() {
        assertDatasetReady(getState, 'revert')
        assertDatasetLimitations(getState, 'revert', [WRITE, READ_WRITE], false)
        assertHasCurrentItem(getState)
        return dispatch(recordActions.revert())
      },

      /**
       * @function refresh
       * @memberof wix-dataset.Dataset
       * @summary Refetches the contents of the dataset from the collection.
       * @syntax
       * function refresh(): Promise<void>
       * @description
       *  The `refresh()` function returns a Promise that is resolved
       *  when:
       *
       *  + The dataset's contents are refetched from the collection, discarding
       *    current edits.
       *  + Any connected page elements have been updated with the values from
       *    the collection (read &amp; write mode) or blank values (write-only mode).
       *
       *
       *  Refreshing the dataset sets the current item back to the first item in
       *  the dataset regardless of what the current item was before refreshing.
       *
       * @note
       *  A dataset needs to load its data before you call its `refresh()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `refresh()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `refresh()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       * @snippet [refresh.es6=Refresh the dataset contents]
       * @snippet [refresh_onReady.es6=Refresh the dataset contents when the page loads]
       * @returns {Promise}
       * @fulfill {void} When the refresh is finished.
       * @reject {String} An error message.
       * @see [revert( )](#revert)
       * @see [save( )](#save)
       */
      async refresh() {
        assertDatasetReady(getState, 'refresh')
        assertDatasetLimitations(
          getState,
          'refresh',
          [READ, WRITE, READ_WRITE],
          false
        )
        try {
          await queue(async () => {
            await dispatch(recordActions.refresh())
          })
        } catch (e) {
          fireErrorEvent('refresh', e)
          throw e
        }
      },

      /**
       * @callback CurrentIndexChangedHandler
       * @summary A current item index change event handler.
       * @param {external:Number} index The new index.
       * @memberof wix-dataset.Dataset
       **/

      /**
       * @function onCurrentIndexChanged
       * @syntax
       * function onCurrentIndexChanged(handler: CurrentIndexChangedHandler): void
       * callback CurrentIndexChangedHandler(index: Number): void
       * @summary Adds an event handler that runs when the current index changes.
       * @param {wix-dataset.Dataset.CurrentIndexChangedHandler} handler The current index change event handler.
       * @description
       *  The `onCurrentIndexChanged()` function allows you to optionally perform actions
       *  right after the current index changes.
       *
       *  Calling `onCurrentIndexChanged()` on a write-only dataset causes an error.
       * @snippet [onCurrentIndexChanged.es6=Register a callback to run when the current index changes]
       * @memberof wix-dataset.Dataset
       */
      onCurrentIndexChanged: cb => {
        assertValidCallback('onCurrentIndexChanged', cb)
        assertDatasetLimitations(
          getState,
          'onCurrentIndexChanged',
          [READ_WRITE, READ],
          false
        )
        return register('currentIndexChanged', userCodeZone(cb))
      },

      /**
       * @callback ItemValuesChangedHandler
       * @summary A current item value change event handler.
       * @param {external:Object} itemBeforeChange The item before the change.
       * @param {external:Object} updatedItem The updated item.
       * @memberof wix-dataset.Dataset
       **/

      /**
       * @function onItemValuesChanged
       * @memberof wix-dataset.Dataset
       * @syntax
       * function onItemValuesChanged(handler: ItemValuesChangedHandler): void
       * callback ItemValuesChangedHandler(itemBeforeChange: Object,
       *   updatedItem: Object): void
       * @summary Adds an event handler that runs when a value of the current item changes.
       * @param {wix-dataset.Dataset.ItemValuesChangedHandler} handler The current value changed event handler.
       * @description
       *  The `onItemValuesChanged()` function allows you to optionally perform actions
       *  right after the current item's values change. The item's value changes
       *  when a user changes the value in one of the item's connected page elements
       *  or you change the value programmatically.
       *
       *  Calling `onItemValuesChanged()` on a read-only dataset causes an error.
       * @snippet [onItemValuesChanged.es6=Register a callback to run when the current item's values change]
       */
      onItemValuesChanged: cb => {
        assertValidCallback('onItemValuesChanged', cb)
        assertDatasetLimitations(
          getState,
          'onItemValuesChanged',
          [READ_WRITE, WRITE],
          false
        )
        return register('itemValuesChanged', userCodeZone(cb))
      },

      /**
       * @typedef wix-dataset.Dataset~DatasetError
       * @summary An object representing a dataset error.
       * @property {external:String} code Error code.
       * @property {external:String} message Error message.
       * @snippet [onError.es6=Register a callback to run when an error occurs]
       */

      /**
       * @callback ErrorHandler
       * @summary An error event handler.
       * @param {external:String} operation The operation during which the error occurred.
       * @param {wix-dataset.Dataset~DatasetError} error The error that occurred.
       * @memberof wix-dataset.Dataset
       **/

      /**
       * @function onError
       * @memberof wix-dataset.Dataset
       * @summary Adds an event handler that runs when an error occurs.
       * @param {wix-dataset.Dataset.ErrorHandler} handler The error handler.
       * @syntax
       * function onError(handler: ErrorHandler): void
       * callback ErrorHandler(operation: string, error: Error): void
       * @description
       *  The `onError()` function allows you to perform actions after a dataset
       *  operation causes an error such as a field failing validation.
       *
       *  The value of the handler's `operation` property is the
       *  name of the function that caused the error.
       *
       *  For example, the `operation` property could be any of the following:
       *
       *  + `"new"`
       *  + `"next"`
       *  + `"save"`
       *  + `"previous"`
       *
       *
       * @snippet [onError.es6=Register a callback to run when an error occurs]
       */
      onError: cb => {
        assertValidCallback('onError', cb)
        assertDatasetLimitations(
          getState,
          'onError',
          [READ_WRITE, READ, WRITE],
          false
        )
        return register('datasetError', userCodeZone(cb))
      },

      /**
       * @callback ReadyHandler
       * @summary A dataset ready event handler.
       * @memberof wix-dataset.Dataset
       **/

      /**
       * @function onReady
       * @memberof wix-dataset.Dataset
       * @syntax
       * function onReady(handler: ReadyHandler): void
       * callback ReadyHandler(): void
       * @summary Adds an event handler that runs when the dataset is ready.
       * @description
       *  The `onReady()` function allows you to optionally perform actions
       *  right after the dataset has loaded its data from the collection and
       *  updated all connected page elements with their corresponding values.
       * @snippet [onReady.es6=Register a callback to run after the dataset is ready]
       */
      onReady: cb => {
        assertValidCallback('onReady', cb)
        assertDatasetLimitations(
          getState,
          'onReady',
          [READ, WRITE, READ_WRITE],
          false
        )
        if (!reducer.isDatasetReady(getState())) {
          return register('datasetReady', userCodeZone(cb))
        } else {
          Promise.resolve(userCodeZone(cb)()).catch(
            reportCallbackError('onReady', errorReporter)
          )
          return noop_
        }
      },

      /**
       * @function setSort
       * @memberof wix-dataset.Dataset
       * @summary Sets the dataset sort order.
       * @param {wix-data.WixDataSort} sort A wix-data sort object.
       * @instance
       * @syntax
       * function setSort(sort: WixDataSort): Promise<void>
       * @description
       *  The `setSort` function sorts the items in the dataset.
       *
       *  The `WixDataSort` object is created by calling the [`sort()`](wix-data.html#sort)
       *  function on `wixData` and chaining one or more of the following Wix Data
       *  sort functions.
       *
       *  + [ascending](wix-data.WixDataQuery.html#ascending)
       *  + [descending](wix-data.WixDataQuery.html#descending)
       *
       *
       *  Setting a dataset's sort overrides existing sorts that were previously
       *  set using the `setSort()` function or using the Dataset Settings
       *  panel in the Editor.
       *
       *  To clear a dataset's current sort, call `setSort()` and pass it an
       *  empty sort. You create an empty sort by calling the [`sort()`](wix-data.html#sort)
       *  function without chaining any of the additional sort functions mentioned above.
       *
       *  Calling `setSort()` on a write-only dataset causes an error.
       *
       *  You will need to import `wix-data` to create a `WixDataSort` object.
       * @snippet [setSort.es6=Set a dataset's sort]
       *  This example sorts a dataset by `lastname` field in ascending order and
       *  when more than one item has the same `lastname` value they are sorted
       *  by the `age` field in descending order.
       * @snippet [setSort_build.es6=Build and set a dataset's sort]
       *  This example builds a sort based on certain conditions. It then uses the
       *  built sort to sort a dataset.
       * @snippet [setSort_then.es6=Set a dataset's sort]
       * @snippet [setSort_clear.es6=Clear a dataset's sorts]
       * @returns {Promise}
       * @fulfill {void} When the sort has been set.
       * @reject {external:String} An error message.
       * @see [setFilter( )](#setFilter)
       */
      async setSort(sort) {
        assertScopeIsNotFixedItem(isFixedItem, 'setSort')
        assertDatasetLimitations(getState, 'setSort', [READ, READ_WRITE], false)
        assertValidSort(sort)

        return queue(async () => {
          try {
            await new Promise(resolve => api.onReady(resolve))

            if (!reducer.isReadOnly(getState())) {
              await flushDraft()
            }

            const buildSort = userCodeZone(() => sort._build())
            await dispatch(configActions.setSort(cloneDeep_(buildSort())))
          } catch (exc) {
            fireErrorEvent('setSort', exc)
            throw exc
          }
        })
      },

      /**
       * @function setFilter
       * @memberof wix-dataset.Dataset
       * @summary Sets the dataset filter.
       * @param {wix-data.WixDataFilter} filter A wix-data filter object.
       * @instance
       * @syntax
       * function setFilter(filter: WixDataFilter): Promise<void>
       * @description
       *  The `setFilter()` function filters out items that don't match the filter
       *  criteria.
       *
       *  The `WixDataFilter` object is created by calling the [`filter()`](wix-data.html#filter)
       *  function on `wixData` and chaining one or more of the following Wix Data
       *  filter functions.
       *
       *  + [and](wix-data.WixDataQuery.html#and): See note below.
       *  + [between](wix-data.WixDataQuery.html#between)
       *  + [contains](wix-data.WixDataQuery.html#contains)
       *  + [endsWith](wix-data.WixDataQuery.html#endsWith)
       *  + [eq](wix-data.WixDataQuery.html#eq)
       *  + [ge](wix-data.WixDataQuery.html#ge)
       *  + [gt](wix-data.WixDataQuery.html#gt)
       *  + [hasAll](wix-data.WixDataQuery.html#hasAll)
       *  + [hasSome](wix-data.WixDataQuery.html#hasSome)
       *  + [le](wix-data.WixDataQuery.html#le)
       *  + [lt](wix-data.WixDataQuery.html#lt)
       *  + [ne](wix-data.WixDataQuery.html#ne)
       *  + [not](wix-data.WixDataQuery.html#not): See note below.
       *  + [or](wix-data.WixDataQuery.html#or): See note below.
       *  + [startsWith](wix-data.WixDataQuery.html#startsWith)
       *
       *
       *  Setting a dataset's filter overrides existing filters that were previously
       *  set using the `setFilter()` function or using the Dataset Settings
       *  panel in the Editor.
       *
       *  To clear a dataset's current filter, call `setFilter()` and pass it an
       *  empty filter. You create an empty filter by calling the [`filter()`](wix-data.html#filter)
       *  function without chaining any of the additional filter functions mentioned above.
       *
       *  Calling `setFilter()` on a write-only dataset causes an error.
       *
       *  You will need to import `wix-data` in order to create a `WixDataFilter` object.
       * @note
       *  When using the [and](wix-data.WixDataQuery.html#and),
       *  [not](wix-data.WixDataQuery.html#not), or [or](wix-data.WixDataQuery.html#or)
       *  functions, be sure to pass them a `WixDataFilter` object created by calling the [`filter()`](wix-data.html#filter)
       *  function and not a `WixDataQuery` object.
       * @snippet [setFilter.es6=Set a dataset's filter]
       *  This example filters a dataset to only have items where the `lastname`
       *  field starts with "D" and the `age` field is greater or equal to 21.
       * @snippet [setFilter_build.es6=Build and set a dataset's filter]
       *  This example builds a filter based on certain conditions. It then uses the
       *  built filter to filter a dataset.
       * @snippet [setFilter_then.es6=Set a dataset's filter]
       * @snippet [setFilter_clear.es6=Clear a dataset's filters]
       * @snippet [setFilter_or.es6=Set a dataset's filter with an or condition]
       * @returns {Promise}
       * @fulfill {void} When the filter has been set.
       * @reject {external:Error} An error object.
       * @see [setSort( )](#setSort)
       */
      async setFilter(filter) {
        assertScopeIsNotFixedItem(isFixedItem, 'setFilter')
        assertDatasetLimitations(
          getState,
          'setFilter',
          [READ, READ_WRITE],
          false
        )
        assertValidFilter(filter)

        return queue(async () => {
          try {
            await new Promise(resolve => api.onReady(resolve))

            if (!reducer.isReadOnly(getState())) {
              await flushDraft()
            }

            const buildFilter = userCodeZone(() => filter._build())
            await dispatch(configActions.setFilter(cloneDeep_(buildFilter())))
          } catch (exc) {
            fireErrorEvent('setFilter', exc)
            throw exc
          }
        })
      },

      /**
       * @function loadMore
       * @memberof wix-dataset.Dataset
       * @summary Loads the next page of data in addition to the current data.
       * @syntax
       * function loadMore(): Promise<void>
       * @description
       *  The `loadMore()` function returns a Promise that is resolved
       *  when:
       *
       *  + The dataset loads the next [page of data](#data-paging).
       *  + Any connected elements have been updated with the new data.
       *
       *
       *  Loading more data into a dataset adds more items to any connected
       *  repeaters. Elements that have their own settings for how many items
       *  are shown at once, such as tables and galleries, are not affected.
       *
       *  Loading more data into a dataset does not:
       *
       *  + Remove any of the items that were previously shown in any connected elements
       *  + Change the dataset's [page](#data-paging) size.
       *  + Change the dataset's current item.
       *
       *
       * @note
       *  A dataset needs to load its data before you call its `loadMore()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `loadMore()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `loadMore()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       * @snippet [loadMore.es6=Load more dataset content]
       * @snippet [loadMore_onReady.es6=Load more dataset content when the page loads]
       * @returns {Promise}
       * @fulfill {void} When the additional data has been loaded and any connected repeaters have been updated.
       * @reject {String} An error message.
       */
      loadMore: async () => {
        assertScopeIsNotFixedItem(isFixedItem, 'loadMore')
        assertDatasetReady(getState, 'loadMore')
        assertDatasetLimitations(
          getState,
          'loadMore',
          [READ, READ_WRITE],
          false
        )
        await queue(async () => {
          await dispatch(recordActions.incrementNumOfPagesToShow())
        })
      },

      /**
       * @function nextPage
       * @memberof wix-dataset.Dataset
       * @summary Moves to the next page of data.
       * @syntax
       * function nextPage(): Promise<Object[]>
       * @description
       *  The `nextPage()` function returns a Promise that is resolved to
       *  an array of the next [page](#data-paging)'s items when:
       *
       *  + The current item is saved in the collection (if necessary).
       *  + The next [page of data](#data-paging) is loaded.
       *  + Any connected elements have been updated with the new data.
       *  + The current item is updated to the first item in the next page.
       *
       *
       *  Going to the next [page of data](#data-paging) replaces the current items
       *  in any connected elements with the new items that correspond to the
       *  next [page of data](#data-paging). Elements that have their own settings for how many items
       *  are shown at once, such as tables and galleries, are not affected.
       *
       *  Calling `nextPage()` on a write-only dataset causes an error.
       * @note
       *  A dataset needs to load its data before you call its `nextPage()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `nextPage()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `nextPage()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       * @snippet [nextPage.es6=Move to the next page of dataset content]
       * @snippet [nextPage_onReady.es6=Move to the next page of dataset content when the page loads]
       * @returns {Promise}
       * @fulfill {external:Object[]} When the next page of data has been loaded and any connected elements have been updated.
       * @reject {String} An error message.
       * @see [previousPage( )](#previousPage)
       * @see [hasNextPage( )](#hasNextPage)
       * @see [loadPage( )](#loadPage)
       */
      async nextPage() {
        assertScopeIsNotFixedItem(isFixedItem, 'nextPage')
        assertDatasetReady(getState, 'nextPage')
        assertDatasetLimitations(
          getState,
          'nextPage',
          [READ, READ_WRITE],
          false
        )
        try {
          return await queue(async () => {
            if (!reducer.isReadOnly(getState())) {
              await flushDraft()
            }
            if (!api.hasNextPage()) {
              throw new DatasetError(
                'NO_SUCH_PAGE',
                'There are no more pages in the dataset'
              )
            }
            await dispatch(recordActions.nextPage())
            const { items } = await recordActions.fetchCurrentPage(
              recordStore,
              getState()
            )
            return items
          })
        } catch (e) {
          fireErrorEvent('nextPage', e)
          throw e
        }
      },

      /**
       * @function previousPage
       * @memberof wix-dataset.Dataset
       * @summary Moves to the previous page of data.
       * @syntax
       * function previousPage(): Promise<Object[]>
       * @description
       *  The `previousPage()` function returns a Promise that is resolved to
       *  an array of the next [page](#data-paging)'s items when:
       *
       *  + The current item is saved in the collection (if necessary).
       *  + The previous [page of data](#data-paging) is loaded.
       *  + Any connected elements have been updated with the new data.
       *  + The current item is updated to the first item in the previous page.
       *
       *
       *  Going to the previous [page of data](#data-paging) replaces the current items
       *  in any connected elements with the new items that correspond to the
       *  previous [page of data](#data-paging). Elements that have their own settings for how many items
       *  are shown at once, such as tables and galleries, are not affected.
       *
       *  Calling `previousPage()` on a write-only dataset causes an error.
       * @note
       *  A dataset needs to load its data before you call its `previousPage()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `previousPage()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `previousPage()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       * @snippet [previousPage.es6=Move to the previous page of dataset content]
       * @snippet [previousPage_onReady.es6=Move to the previous page of dataset content when the page loads]
       * @returns {Promise}
       * @fulfill {external:Object[]} When the previous page of data has been loaded and any connected repeaters have been updated.
       * @reject {String} An error message.
       * @see [nextPage( )](#previousPage)
       * @see [hasPreviousPage( )](#hasNextPage)
       * @see [loadPage( )](#loadPage)
       */
      async previousPage() {
        assertScopeIsNotFixedItem(isFixedItem, 'previousPage')
        assertDatasetReady(getState, 'previousPage')
        assertDatasetLimitations(
          getState,
          'previousPage',
          [READ, READ_WRITE],
          false
        )
        try {
          return await queue(async () => {
            if (!reducer.isReadOnly(getState())) {
              await flushDraft()
            }
            if (!api.hasPreviousPage()) {
              throw new DatasetError(
                'NO_SUCH_PAGE',
                'This is the first page in the dataset'
              )
            }
            await dispatch(recordActions.previousPage())
            const { items } = await recordActions.fetchCurrentPage(
              recordStore,
              getState()
            )
            return items
          })
        } catch (e) {
          fireErrorEvent('previousPage', e)
          throw e
        }
      },

      /**
       * @function hasNextPage
       * @memberof wix-dataset.Dataset
       * @summary Indicates if there is a next page of data.
       * @syntax
       * function hasNextPage(): boolean
       * @returns {external:Boolean}
       * @description
       *  Returns `true` if the current [page](#data-paging) is not the last
       *  [page](#data-paging) in the dataset.
       *
       *  Returns `false` if the current [page](#data-paging) is the last
       *  [page](#data-paging) in the dataset.
       *
       *  Calling `hasNextPage()` on a write-only dataset causes an error.
       * @note
       *  A dataset needs to load its data before you call its `hasNextPage()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `hasNextPage()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `hasNextPage()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       * @snippet [hasNextPage.es6=Get whether the current page is the last page of data]
       * @snippet [hasNextPage_onReady.es6=Get whether the current page is the last page of data when the page loads]
       * @see [nextPage( )](#previousPage)
       * @see [hasPreviousPage( )](#hasNextPage)
       */
      hasNextPage() {
        assertDatasetLimitations(getState, 'hasNextPage', [READ, READ_WRITE])
        const state = getState()
        const paginationData = reducer.getPaginationData(state)
        return (
          paginationData.offset + reducer.getCurrentPageSize(state) <
          getTotalCount()
        )
      },

      /**
       * @function hasPreviousPage
       * @memberof wix-dataset.Dataset
       * @summary Indicates if there is a previous page of data.
       * @syntax
       * function hasPreviousPage(): boolean
       * @returns {external:Boolean}
       * @description
       *  Returns `true` if the current [page](#data-paging) is not the first
       *  [page](#data-paging) in the dataset.
       *
       *  Returns `false` if the current [page](#data-paging) is the first
       *  [page](#data-paging) in the dataset.
       *
       *  Calling `hasPreviousPage()` on a write-only dataset causes an error.
       *
       * @note
       *  A dataset needs to load its data before you call its `hasPreviousPage()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `hasPreviousPage()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `hasPreviousPage()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       * @snippet [hasPreviousPage.es6=Get whether the current page is the first page of data]
       * @snippet [hasPreviousPage_onReady.es6=Get whether the current page is the first page of data when the page loads]
       * @see [previousPage( )](#previousPage)
       * @see [hasNextPage( )](#hasNextPage)
       */
      hasPreviousPage() {
        assertDatasetLimitations(getState, 'hasPreviousPage', [
          READ,
          READ_WRITE
        ])
        const paginationData = reducer.getPaginationData(getState())
        return paginationData.offset > 0
      },

      /**
       * @function getTotalPageCount
       * @memberof wix-dataset.Dataset
       * @summary Gets the number of pages in the dataset.
       * @syntax
       * function getTotalPageCount(): number
       * @returns {external:Number}
       * @description
       *  The number of [pages](#data-paging) in a dataset is the number of total items divided
       *  by the page size. If there are any left over items, one more page is added.
       *
       *  For example, if you have 20 items and your page size is 6, you have
       *  4 pages. The first 3 pages have 6 items each and the last page has 2 items.
       * @note
       *  A dataset needs to load its data before you call its `getTotalPageCount()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `getTotalPageCount()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `getTotalPageCount()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       * @snippet [getTotalPageCount.es6=Get the number of pages in the dataset]
       */
      getTotalPageCount() {
        assertDatasetLimitations(getState, 'getTotalPageCount', [
          READ,
          READ_WRITE
        ])

        return reducer.getTotalPageCount(getState(), getTotalCount())
      },

      /**
       * @function getCurrentPageIndex
       * @memberof wix-dataset.Dataset
       * @summary Gets the index of the dataset's current page.
       * @syntax
       * function getCurrentPageIndex(): number
       * @returns {external:Number}
       * @description
       *  The `getCurrentPageIndex()` function returns the index of current dataset
       *  [page](#data-paging). The indices of the dataset's pages start at `1`.
       * @note
       *  A dataset needs to load its data before you call its `getCurrentPageIndex()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `getCurrentPageIndex()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `getCurrentPageIndex()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       * @snippet [getCurrentPageIndex.es6=Get the current page's index]
       */
      getCurrentPageIndex() {
        assertDatasetLimitations(getState, 'getCurrentPageIndex', [
          READ,
          READ_WRITE
        ])

        return getCurrentIndex() === undefined
          ? null
          : reducer.getCurrentPageIndex(getState())
      },

      /**
       * @function loadPage
       * @memberof wix-dataset.Dataset
       * @summary Loads the specified page.
       * @syntax
       * function loadPage(pageIndex: number): Promise<Object[]>
       * @param {external:Number} pageIndex The index of the page to load.
       * @description
       *  The `loadPage()` function returns a Promise that is resolved to
       *  an array of the specified [page](#data-paging)'s items when:
       *
       *  + The current item is saved in the collection (if necessary).
       *  + The specified [page of data](#data-paging) is loaded.
       *  + Any connected elements have been updated with the new data.
       *  + The current item is updated to the first item in the loaded page.
       *
       *
       *  The indices of the dataset's [pages](#data-paging) start at `1`.
       * @note
       *  A dataset needs to load its data before you call its `loadPage()` function.
       *  Usually a dataset finishes loading a short time after the page it is on finishes
       *  loading. So if you call `loadPage()` inside the page’s [`onReady()`]($w.html#onReady)
       *  event handler, the dataset might not be ready yet.
       *
       *  To call `loadPage()` as soon as possible after a page loads, use the **dataset's**
       *  [`onReady()`](#onReady) function inside the **page’s** [`onReady()`]($w.html#onReady)
       *  event handler to ensure that both the page and the dataset have finished loading.
       * @snippet [loadPage.es6=Move to the fourth page of dataset content]
       * @snippet [loadPage_onReady.es6=Move to the fourth page of dataset content when the page loads]
       * @returns {Promise}
       * @fulfill {external:Object[]} When the specified page has been loaded and any connected elements have been updated.
       * @reject {String} An error message.
       * @see [previousPage( )](#previousPage)
       * @see [nextPage( )](#nextPage)
       */
      async loadPage(pageNumber) {
        assertDatasetLimitations(
          getState,
          'loadPage',
          [READ, READ_WRITE],
          false
        )
        assertScopeIsNotFixedItem(isFixedItem, 'loadPage')
        assertDatasetReady(getState, 'loadPage')
        assertValidPageIndex(pageNumber, api.getTotalPageCount())

        try {
          return await queue(async () => {
            if (!reducer.isReadOnly(getState())) {
              await flushDraft()
            }
            await dispatch(recordActions.loadPage(pageNumber))
            const { items } = await recordActions.fetchCurrentPage(
              recordStore,
              getState()
            )
            return items
          })
        } catch (e) {
          fireErrorEvent('loadPage', e)
          throw e
        }
      },

      handshake(handshakeInfo) {
        assertDatasetLimitations(
          getState,
          'handshake',
          [READ, READ_WRITE, WRITE],
          false
        )
        assetValidHandshakeInfo(handshakeInfo)

        handshakes.push(handshakeInfo)
        performHandshake(dependenciesManager, dispatch, handshakeInfo)
      },

      inScope: (componentId, itemId) => {
        assertDatasetLimitations(
          getState,
          'inScope',
          [READ, WRITE, READ_WRITE],
          false
        )
        const controller = controllerStore.getController({
          componentId,
          itemId
        })
        return controller ? controller.staticExports : api
      },

      isConnectedToComponent: componentId => {
        assertDatasetLimitations(
          getState,
          'isConnectedToComponent',
          [READ, WRITE, READ_WRITE],
          false
        )
        return reducer.getConnectedComponents(getState()).includes(componentId)
      },

      /**
       * @function getPageSize
       * @memberof wix-dataset.Dataset
       * @summary Gets the dataset's page size.
       * @syntax
       * function getPageSize(): number
       * @returns {external:Number}
       * @description
       *  Gets the current [page](#data-paging) size set in the Editor or using the
       *  [`setPageSize`](#setPageSize) function.
       * @snippet [getPageSize.es6=Get the current page size]
       * @see [setPageSize( )](#setPageSize)
       */
      getPageSize: () => {
        assertDatasetLimitations(getState, 'getPageSize', [READ, READ_WRITE])
        return reducer.getPaginationData(getState()).size
      },

      /**
       * @function setPageSize
       * @memberof wix-dataset.Dataset
       * @summary Sets the dataset's page size.
       * @param {external:Number} pageSize The new page size.
       * @instance
       * @syntax
       * function setPageSize(pageSize: number): Promise<void>
       * @description
       *  The `setPage()` function returns a Promise that is resolved when:
       *
       *  + The current item is saved in the collection (if necessary).
       *  + The dataset's [page](#data-paging) size is reset.
       *  + The dataset is refreshed, and any connected elements have been updated.
       *
       *
       *  Because setting the [page](#data-paging) size refreshes the dataset, the dataset's
       *  current item is reset to the first item in the dataset.
       *
       *  Calling `setPageSize()` on a write-only dataset causes an error.
       * @snippet [setPageSize.es6=Set a dataset's page size]
       * @returns {Promise}
       * @fulfill {void} When the page size has been set.
       * @reject {external:Error} An error object.
       * @see [getPageSize( )](#getPageSize)
       */
      async setPageSize(size) {
        assertDatasetLimitations(
          getState,
          'setPageSize',
          [READ, READ_WRITE],
          false
        )
        assertValidNaturalNumber('size', size)

        return queue(async () => {
          await new Promise(resolve => api.onReady(resolve))

          try {
            if (!reducer.isReadOnly(getState())) {
              await flushDraft()
            }
            await dispatch(datasetActions.setPaginationData({ size }))
          } catch (e) {
            fireErrorEvent('setPageSize', e)
            throw e
          }
        })
      }
    }

    const routerDatasetApi = routerDatasetApiCreator({
      datasetType,
      siblingDynamicPageUrlGetter
    })

    const api = mapValues_(
      Object.assign({}, baseApi, routerDatasetApi),
      (apiFunction, functionName) =>
        verboseWrapper(verboseReporter, apiFunction, functionName)
    )
    return mapValues_(api, (apiFunction, functionName) =>
      flow_(logger.applicationCodeZone, addBreadcrumb(functionName))(
        apiFunction
      )
    )
  }
}

module.exports = datasetApiCreator
