'use strict'

const noop_ = require('lodash/noop')
const { union } = require('folktale/adt/union')

const fedopsLoggerFactory = require('@wix/fedops-logger/dist/src/logger-factory')
const createOptions = require('../fedops/createOptions')

const id = 'FEDOPS'

const Environment = union('Environment', {
  NotInitialized() {},
  SSR({ appLogger }) {
    const options = createOptions(appLogger)
    const logger = fedopsLoggerFactory.create(
      'wix-code-dbsm-viewer-app-ssr',
      options
    )
    return { logger }
  },
  Client({ appLogger }) {
    const options = createOptions(appLogger)
    const logger = fedopsLoggerFactory.create(
      'wix-code-dbsm-viewer-app',
      options
    )
    return { logger }
  }
})

const fedopsHandlerCreator = () => {
  let environment = Environment.NotInitialized()
  const tracesMap = new Map()

  const handleLogEvent = (logger, logEvent) => {
    logEvent.matchWith({
      TraceStart: ({ traceId, actionName }) => {
        const { timeoutId } = logger.interactionStarted(actionName)
        tracesMap.set(traceId, { timeoutId })
      },
      TraceEnd: ({ traceId, actionName }) => {
        const { timeoutId } = tracesMap.get(traceId)
        tracesMap.delete(traceId)
        logger.interactionEnded(actionName, { timeoutId })
      },
      [union.any]: noop_
    })
  }

  const fedopsHandler = () => ({
    id,
    init: ({ inSsr, logger: appLogger }) => {
      environment = inSsr
        ? Environment.SSR({ appLogger })
        : Environment.Client({ appLogger })
    },
    log: logEvent => {
      environment.matchWith({
        Client: ({ logger }) => handleLogEvent(logger, logEvent),
        SSR: ({ logger }) => handleLogEvent(logger, logEvent),
        NotInitialized: () => {
          throw new Error(
            `You cannot report to fedops before setting the logger environment.
              Make sure you call logger.init before reporting.`
          )
        }
      })
    }
  })

  return fedopsHandler
}

module.exports.id = id
module.exports.fedopsHandlerCreator = fedopsHandlerCreator
