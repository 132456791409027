'use strict'

const {
  traceHandlerIds: { FEDOPS, MONITORING_HUB, SYSTEM_TRACING }
} = require('../logger')

const initAppForPage = () => ({
  actionName: 'databinding/initAppForPage',
  reportToHandlers: [FEDOPS, MONITORING_HUB, SYSTEM_TRACING]
})

const createControllers = () => ({
  actionName: 'databinding/createControllers',
  reportToHandlers: [FEDOPS, MONITORING_HUB, SYSTEM_TRACING]
})

const loadSchemas = () => ({
  actionName: 'databinding/loadSchemas',
  reportToHandlers: [FEDOPS, MONITORING_HUB, SYSTEM_TRACING]
})

const findRecords = ({ collectionName, filter, sort, offset, length }) => ({
  actionName: 'dataset/findRecords',
  reportToHandlers: [MONITORING_HUB, SYSTEM_TRACING],
  params: {
    message: {
      collectionName,
      filter,
      sort,
      offset,
      length
    }
  }
})

const pageReady = () => ({
  actionName: 'dataset/pageReady',
  reportToHandlers: [FEDOPS, MONITORING_HUB, SYSTEM_TRACING]
})
const pageReadyGetData = () => ({
  actionName: 'dataset/pageReady/getData',
  reportToHandlers: [FEDOPS, MONITORING_HUB, SYSTEM_TRACING]
})

const repeaterItemReady = index => ({
  actionName: `connectedRepeaterAdapter itemReady ${index}`,
  reportToHandlers: [SYSTEM_TRACING]
})

const repeaterSetData = () => ({
  actionName: 'connectedRepeaterAdapter setting repeater.data',
  reportToHandlers: [SYSTEM_TRACING]
})

const repeaterRecordSetLoaded = () => ({
  actionName: `connectedRepeaterAdapter recordSetLoaded`,
  reportToHandlers: [SYSTEM_TRACING]
})

const repeaterCurrentViewChanged = () => ({
  actionName: `connectedRepeaterAdapter currentViewChanged`,
  reportToHandlers: [SYSTEM_TRACING]
})

module.exports.initAppForPage = initAppForPage
module.exports.createControllers = createControllers
module.exports.findRecords = findRecords
module.exports.loadSchemas = loadSchemas
module.exports.pageReady = pageReady
module.exports.pageReadyGetData = pageReadyGetData
module.exports.repeaterItemReady = repeaterItemReady
module.exports.repeaterSetData = repeaterSetData
module.exports.repeaterRecordSetLoaded = repeaterRecordSetLoaded
module.exports.repeaterCurrentViewChanged = repeaterCurrentViewChanged
