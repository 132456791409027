'use strict'

const noop_ = require('lodash/noop')
const { union } = require('folktale/adt/union')
const sendBiOnError = require('../bi/sendBiOnError')
const { setupBiLogger, setupErrorBiLogger } = require('../bi/setupBiLogger')
const getDefaultBIParams = require('../bi/getDefaults')
const {
  errorBoundaryScopes: { USER_SCOPE }
} = require('../error-boundaries/errorBoundaries')

const Environment = union('Environment', {
  NotInitialized() {},
  SSR() {},
  Client({ viewMode, platformBiParams }) {
    const biLogger = setupBiLogger(
      viewMode,
      getDefaultBIParams(platformBiParams, viewMode)
    )
    const errorBiLogger = setupErrorBiLogger()
    return { biLogger, errorBiLogger }
  }
})

const biHandlerCreator = () => {
  let environment = Environment.NotInitialized()

  const biHandler = () => ({
    init: ({ inSsr, viewMode, platformBiParams }) => {
      environment = inSsr
        ? Environment.SSR()
        : Environment.Client({ viewMode, platformBiParams })
    },
    log: logEvent => {
      logEvent.matchWith({
        BI: ({ biEvent }) => {
          environment.matchWith({
            Client: ({ biLogger }) => biLogger(biEvent),
            SSR: noop_,
            NotInitialized: () => {
              throw new Error(
                `You cannot report to BI before setting the logger environment.
                  Make sure you call logger.init before reporting.`
              )
            }
          })
        },
        Error: ({ error, options: { zone } }) => {
          environment.matchWith({
            Client: ({ errorBiLogger }) => {
              if (zone !== USER_SCOPE) {
                sendBiOnError(errorBiLogger, error)
              }
            },
            [union.any]: noop_
          })
        },
        [union.any]: () => {}
      })
    }
  })

  return biHandler
}

module.exports.biHandlerCreator = biHandlerCreator
